import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  markAsBlocked, markAsExternalProspect, markAsFavorite, markAsLockEnable, markAsMyProject, markAsProject, markAsProjectEnable, markAsProspect, updateFavorites,
  updateProspects
} from './../../helpers/workMarkedHelper';

//Card
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
//import Rating from './../Rating/Rating';
//import Add from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Done from '@material-ui/icons/Done';
import Favorite from '@material-ui/icons/Favorite';
import Language from '@material-ui/icons/Language';
import Lock from '@material-ui/icons/Lock';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import { LanguageConsumer } from './../../language/LanguageContext';
import MediaModalSlider from './../ProductDetail/MediaModalSlider';
import ls from 'local-storage';
//Required images
import defaultImage from '../../../static/images/default.png';
import radionovelaImage from '../../../static/images/RADIONOVELA.jpg';
import iconPlay from '../../../static/images/iconplay.png';
import { sendNotification } from '../../services/notifications';
import { apiPOST, returnCurrentUser, returnCurrentUserId } from '../../services/service_base';
//Styles
import VideoThumbnail from 'react-video-thumbnail';
import styles from './cardStyle-jss';
import './style.css';
import { Grid } from '@material-ui/core';
import { gaEvents } from '../../services/gaEvents';
import { validateNullValues } from '../../utils/validation';

class ProductCard extends React.Component {

  constructor(props) {
    super(props);
    //AHDA 31/05/2019: Changed selected
    this.state = {
      selectedFav: false,
      selectedBlock: false,
      selectedAdd: false,
      blocked: {},
      openModal: false,
      open: false,
      originalUrl: '',
      auxMultimedia: '',
      productor: false,
      validity_options: null
    };
  }

  //Add to favorites
  handleFavorite = (idObra, titulo_original) => {

    const request = {
      user: returnCurrentUserId(),
      idObra: idObra,
      isAdded: null,
      screen: this.props.screen
    }

    if (this.state.selectedFav) {
      request.isAdded = false;
    } else {
      request.isAdded = true;
      gaEvents.eventFav(titulo_original);
    }

    //AHDA 19/06/2019: Added state before petition so no delay is showed
    const added = this.state.selectedFav ? true : false;
    this.setState({ selectedFav: !added });

    apiPOST('/obras/markAsFavorite', request).then(
      response => {
        if (response.status === 200) {
          if (added) {
            updateFavorites(idObra, false);
            if (this.props.addToList) {
              this.props.addToList(this.props.product, 100, false)
            }
          } else {
            updateFavorites(idObra, true);
            if (this.props.addToList) {
              this.props.addToList(this.props.product, 100, true)
            }
          }
        } else {
          //Return to older state if failed
          this.setState({ selectedFav: added });
        }
      }
    ).catch(
      error => {
        //Return to older state if failed
        this.setState({ selectedFav: added });
      }
    );
  };

  //--------------------------------- Modal
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  }
  //--------------------------------- End Modal

  //--------------------------------- Notifications
  handleCloseNotification = () => {
    this.setState({ open: false })
  }
  //--------------------------------- End Notifications

  //--------------------------------- Helpers 
  addDefaultSrc(ev, product) {
    let event = ev.event;
    if (product.thumbnail) {
      Storage.get(product.thumbnail).then(
        response => {
          this.setState(
            {
              originalUrl: response
            }
          )
        }
      ).catch(
        err => {
          console.log("error", err);
        }
      )
    } else {
      ev.target.src = defaultImage;
    }
  }

  //AHDA 19/06/2019: passed method from render
  BlockedDiv = (blocked, enableProjectDetails) => {
    if (blocked.isBlocked) {
      return <Tooltip
        onClick={(this.props.canViewWorkBlock && (enableProjectDetails)
        ) ? this.props.detailOpen : null}
        title={blocked.reason === "project" ? "Esta obra ya está asignada a un usuario" : "Esta obra está en revisión por otro usuario"} placement="right">
        <div className={this.props.classes.blockedCard}></div>
      </Tooltip>
    } else {
      return null;
    }
  }
  //--------------------------------- End Helpers 

  //Lifecycle
  componentDidMount() {
    this.setState({
      selectedFav: markAsFavorite(this.props.product.id_obras),
      selectedBlock: markAsProject(this.props.product.id_obras) ? true : markAsMyProject(this.props.product.id_obras) ? true : false,
      selectedAdd: markAsProspect(this.props.product.id_obras) ? true : markAsExternalProspect(this.props.product.id_obras) ? true : false
    });

    let url = (this.props.product && this.props.product.multimedia && this.props.product.multimedia.length > 0) ? this.props.product.multimedia.filter(x => x.type === 'video')[0] : undefined
    if (url) {
      Storage.get(url.url).then(
        response => {
          this.setState(
            {
              auxMultimedia: response
            }
          )
        }
      )
    }

    if (ls.get("user_object").rdsUser.asigned_profile === "Productor") {
      this.setState({ productor: true });
    }

    if (this.props.product.validity_options) {
      this.setState({ validity_options:  JSON.parse(this.props.product.validity_options) });
    }
  }

  componentWillReceiveProps(newProps) {
    //AHDA 19/06/2019: Validation for changes in favorites, prospects and projects 
    if ((newProps.product.id_obras !== this.props.product.id_obras)
      || newProps.favorites != this.props.favorites
      || newProps.prospects != this.props.prospects
      || newProps.projects != this.props.projects
    ) {
      this.setState({
        selectedFav: markAsFavorite(newProps.product.id_obras),
        selectedBlock: markAsProject(newProps.product.id_obras) ? true : markAsMyProject(newProps.product.id_obras) ? true : false,
        selectedAdd: markAsProspect(newProps.product.id_obras) ? true : markAsExternalProspect(newProps.product.id_obras) ? true : false,
      });
    }
  }

  render() {
    const {
      classes, detailOpen, width, product, lista, showDate, canFavorite,
      size,
      productor,
      imagenLarge,
      cardMargin,
    } = this.props;

    let thumb = defaultImage;
    //isanchez 2.05.2019
    if (size !== "") {
      if (product.multimedia) {
        if (product.multimedia.length > 0) {
          if (product.multimedia[0].arr) {
            product.multimedia[0].arr.map(item => {
              if (item[size]) {
                thumb = item[size];
              }
            })
          }
        }
      }
    }

    //Play button 
    const PlayButton = () => {
      if (product.multimedia.length > 0 && product.multimedia.find(material => material.type === "video") !== undefined) {
        return (
          <div className={classes.overlay}>
            <img src={iconPlay} onClick={this.handleOpenModal} alt="" />
          </div>
        )
      }
      else {
        return null;
      }
    }

    const GetImg = (product, classes) => {

      if ((product.multimedia && product.multimedia.length > 0) && product.multimedia.filter(x => x.type === 'video')[0] && !product.multimedia.filter(x => x.type === 'img')[0]) {
        return <div className={imagenLarge ? classes.thumbVideoProfile : classes.thumbVideo}><VideoThumbnail videoUrl={this.state.auxMultimedia} /></div>
      } else {
        if (!product.thumbnail) {
          return product.formato === 'Radionovela' ? <img src={radionovelaImage} alt="Televisa Univision" /> : <img src={defaultImage} alt="Televisa Univision" />;
        } else if (product.thumbnail === "") {
          return product.formato === 'Radionovela' ? <img src={radionovelaImage} alt="Televisa Univision" /> : <img src={defaultImage} alt="Televisa Univision" />;
        } else if (thumb != defaultImage) {
          return <img src={this.state.originalUrl ? this.state.originalUrl : thumb} onError={(event) => this.addDefaultSrc(event, product)} />
        } else {
          return <S3Image imgKey={product.thumbnail} />
        }
      }
    }

    const blocked = markAsBlocked(product.id_obras);
    const enableBlockedDetails = markAsLockEnable(product.id_obras)
    const enableProjectDetails = productor? markAsProjectEnable(product.id_obras): true;

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <React.Fragment>
              {/*CH 21/06/2019: Added Imagen Large*/}
              <Card className={classNames(imagenLarge ? classes.cardProductLarge : classes.cardProduct, isWidthUp('sm', width) && lista ? classes.cardList : '', cardMargin && classes.cardMargin)}>
                {
                  this.BlockedDiv(blocked,enableProjectDetails)
                }

                <CardMedia
                  className={(product.thumbnail && product.thumbnail !== "") ? classes.mediaProduct : classes.mediaProductEmpty}
                  image={defaultImage}
                  title={product.titulo_original}
                  onClick={this.handleOpenModal}
                >
                  {
                    GetImg(product, classes)
                  }
                </CardMedia>
                {
                  validateNullValues(product.multimedia) && <PlayButton />
                }
                <CardContent className={classes.floatingButtonWrap} >
                  <div className={classes.contentButt}>
                    {
                      (this.state.validity_options && this.state.validity_options.last_production && this.state.validity_options.last_production!=="none") &&
                      <Tooltip title={lang.markedWorks.lastProduction} placement="top">
                        <Button variant="fab" mini className={classNames(classes.btnTop, classes.buttonAddList, classes.activadoBlock)}>
                          {this.state.validity_options.last_production}
                        </Button>
                      </Tooltip>
                    }
                    <Tooltip title={lang.markedWorks.favorites} placement="top">
                      <Button disabled={!canFavorite} variant="fab" mini className={classNames(classes.btnTop, classes.buttonAddList, this.state.selectedFav === true ? classes.activadoFav : classes.desactivado)} onClick={() => this.handleFavorite(product.id_obras, product.titulo_original)}>
                        <Favorite />
                      </Button>
                    </Tooltip>
                      <Tooltip title={this.state.selectedAdd !== true ? lang.markedWorks.prospectAvailable : lang.markedWorks.prospect} placement="top">
                        <Button
                          variant="fab" mini
                          className={classNames(classes.buttonAddList, this.state.selectedAdd === true ? classes.activadoBlock : classes.disableds)}
                        >
                          <Done />
                        </Button>
                      </Tooltip>
                    <Tooltip title={this.state.selectedBlock !== true ? lang.markedWorks.projectAvailable : lang.markedWorks.project} placement="top">
                      <Button variant="fab" mini className={classNames(classes.buttonAddList, this.state.selectedBlock === true ? classes.activadoBlock : classes.disableds)}>
                        <Lock />
                      </Button>
                    </Tooltip>
                  </div>
                  <div style={{ cursor: 'pointer' }}
                    onClick={
                      detailOpen}
                  >
                    <Typography noWrap gutterBottom variant="headline" className={classes.title} component="h2">
                      {product.titulo_original}
                    </Typography>
                    <Typography component="p" className={classes.autor}>
                      {product.autores}&nbsp;
                    </Typography>
                    <Typography component="i" className={classes.desc}>
                      {product.logline}
                    </Typography>
                    {
                      showDate ?
                        <Typography variant="headline" className={classes.dateFinish}>
                          Proyecto finalizado: <span>{product.finishedDate}</span>
                        </Typography> : null
                    }
                  </div>
                  <div className={classes.ratting}>
                    {/* <Rating value={ratting} max={5} readOnly /> */}
                  </div>

                </CardContent>
                <CardActions className={classes.price}>
                  <Grid container spacing={8} alignItems='flex-end' style={{ height: '108px' }}>
                    <Grid item container spacing={8} alignItems='center'>
                      <Grid item xs={6}>
                        <Chip label={["Primigenia", "Adaptación"].includes(product.tipo_obra) ? product.tipo_obra : (product.tipo_obra === "primigenia" ? "Primigenia" : "Adaptación")} className={getChipClassNames(classes, product.tipo_obra)} />
                      </Grid>

                      {(product.obra_vigencia && !(productor ? productor : this.state.productor)) &&
                        <Grid item xs={6}>
                          <Chip label={product.obra_vigencia} className={getChipClassNames(classes, product.obra_vigencia)} />
                        </Grid>
                      }

                      {product.produccion &&
                        <Grid item xs={6}>
                          <Chip label={product.produccion} className={getChipClassNames(classes, product.produccion)} />
                        </Grid>
                      }

                      <Grid item xs={6}>
                        <Typography component="span" className={classes.pais} title={product.pais}>
                          <Language /> {product.pais}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {
                          enableProjectDetails &&
                          <Button size="small" variant="outlined" color="primary" fullWidth
                            onClick={detailOpen}
                            className={classes.btnSmall}>
                            {lang.common.watchMore} <NavigateNext />
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*Modal for media*/}
                  {
                    !validateNullValues(product.multimedia) ? null : product.multimedia.length > 0 ?
                      <Modal
                        aria-labelledby="media-modal"
                        aria-describedby=""
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                        className="modalArrows dark"
                      >
                        <MediaModalSlider
                          openModal={this.state.openModal}
                          handleCloseModal={this.handleCloseModal}
                          media={product.multimedia}
                        />
                      </Modal> : null
                  }
                </CardActions>
              </Card>
            </React.Fragment>
        }
      </LanguageConsumer>
    );
  }
}

ProductCard.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.string,
  list: PropTypes.bool,
  detailOpen: PropTypes.func,
  product: PropTypes.object,
  handleNotifications: PropTypes.func,
  renderVentas: PropTypes.bool,
  renderProduccion: PropTypes.bool,
  lista: PropTypes.bool,
  showDate: PropTypes.bool,
  canFavorite: PropTypes.bool,
  canAddProspect: PropTypes.bool,
  canViewProduction: PropTypes.bool, //AHDA 03/06/2019
  canViewSales: PropTypes.bool, //AHDA 03/06/2019
  favorites: PropTypes.number, //AHDA 19/06/2019
  prospects: PropTypes.number, //AHDA 19/06/2019
  projects: PropTypes.number, //AHDA 19/06/2019
  imagenLarge: PropTypes.bool, //CH 21/06/2019
  cardMargin: PropTypes.bool,
};

ProductCard.defaultProps = {
  list: false,
  detailOpen: () => (false),
  product: {},
  handleNotifications: () => (false),
  renderVentas: true,
  renderProduccion: true,
  showDate: false,
  size: '',
  canViewProduction: true, //AHDA 03/06/2019
  canViewSales: true, //AHDA 03/06/2019
  favorites: 0, //AHDA 19/06/2019
  prospects: 0, //AHDA 19/06/2019
  projects: 0, //AHDA 19/06/2019
  imagenLarge: false, //CH 21/06/2019
};

const ProductCardResponsive = withWidth()(ProductCard);
export default withStyles(styles)(ProductCardResponsive);


/**
 * Returns the corresponding value for a `Clasificacion` value.
 * @see src\app\language\lang.js 
 * 
 *  ventas: "Ventas", 
 *  produccion: "Producción",
 *  adptation: "Adaptación", 
 *  original:"Primigenia",
 *  vigente: "Vigente",
 *  vencida: "Vencida",
 *  porVencer: "Por vencer"
 *  produccionTelevisa: "Producción Televisa",
 *  produccionExtranjera: "Producción Extranjera",
 *  noProducida: "No producida",
 *  inedita: "Inédita"
 * 
 * @param {string} languageContext Language context
 * @param {string} clasificacion Clasificacion value.
 * @returns {string} Corresponding label for the `clasificacion`.
 */
function getChipLabel(languageContext, clasificacion) {
  return languageContext.playDetail[clasificacion];
}

/**
 * Returns the corresponding color for a `Clasificacion` value.
 * 
 *  ventas,
 *  produccion,
 *  adptation -> adaptacion,
 *  original -> primigenia,
 *  vigente,
 *  vencida,
 *  porVencer,
 *  produccionTelevisa,
 *  produccionExtranjera,
 *  noProducida,
 *  inedita
 * 
 * @param {ClassNameMap<string>>} classes 
 * @param {string} clasificacion 
 * @returns {string} Corresponding color for the `clasificacion`.
 */
function getChipClassNames(classes, clasificacion) {

  let color = null;
  let base = classes.chipBase;

  switch (clasificacion) {
    case 'ventas':
      color = classes.chipPrimary;
      break;
    case 'Vigente':
      color = classes.chipVigente;
      break;
    case 'Por Vencer':
      color = classes.chipPorVencer;
      break;
    case 'Vencida':
    case 'Sin Derechos':
    case 'Hold Back': color = classes.chipVencida;
      break;
    case 'original':
    case 'primigenia':
      base = [classes.chipTipo];
      color = classes.chipPrimigenia
      break;
    case 'adaptacion':
      base = [classes.chipTipo];
      color = classes.chipAdaptacion
      break;
    case 'Producción Televisa':
      base = [classes.chipTipo, classes.chipTipoBorder];
      color = classes.chipProduccionTelevisa
      break;
    case 'Producción Extranjera':
      base = [classes.chipTipo, classes.chipTipoBorder];
      color = classes.chipProduccionExtranjera
      break;
    case 'No producida':
      base = [classes.chipTipo, classes.chipTipoBorder];
      color = classes.chipNoProducida
      break;
    case 'Inédita':
      base = [classes.chipTipo, classes.chipTipoBorder];
      color = classes.chipInedita
      break;
    default:
      color = classes.chipDefault;
      break;
  }

  return classNames(
    base,
    color
  );
}