const styles = theme => ({
  evaluadora: {
    '& tr td:nth-child(7)': {
      color: theme.palette.primary.main
    }
  },
  black: {
    backgroundColor: '#000',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    // width: 145,
    width: '100%',
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  textField: {
    margin: theme.spacing.unit,
    width: 180,
  },
  button: {
    margin: theme.spacing.unit,
    // maxHeight: 30
  },
  botonFlotante: {
    position: 'absolute',
    top: theme.spacing.unit * 8,
    right: 0,
    marginRight: theme.spacing.unit * 4,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  //
  rootTable: {
    width: '100%',
    //marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  headerTable: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center',
      '& h2': {
        fontSize: 16
      }
    },
  },
  bitacoraMov: {
    '& th': {
      paddingRight: 0
    },
    '& td': {
      paddingRight: 0
    }
  },
  comment: {
    alignItems: 'center',
    display: 'flex',
    borderBottom: '1px dotted rgba(0, 0, 0, 0.42)',
    paddingBottom: '7px',
  },
  smallbutton: {
    padding: '6px'
  },
  iconxs: {
    fontSize: '18px'
  },
  input: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px',
    lineHeight: 1.3,
    maxHeight: '76px',
    overflowY: 'auto',
    paddingTop: '6px',
    width: '220px',

  },
  w100:{
    width: '100%',
    marginLeft: '10px'
  },
  fw300: {
    fontWeight: 300,
  },
  mb4: {
    marginBottom: '1.5rem'
  },
  positionRelative: {
    position: 'relative'
  },
  list: {
    borderRadius: '4px',
    position: 'absolute',
    width: 'calc(100% - 16px)',
    zIndex: 999,

    '& >div': {
      whiteSpace: 'break-spaces',
      height: 'auto'
    }
  }
});

export default styles;