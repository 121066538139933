
import withSession from './withSession'; //AHDA 17/05/2019

import App from '../components/App/App';
import Dashboard from '../components/App/Dashboard';
import Outer from '../components/App/Outer';
import OuterEmpty from '../components/App/OuterEmpty';

// Pages
import Login from './Pages/Users/Login';
import LockScreen from './Pages/Users/LockScreen';
import LoginDedicated from './Pages/Standalone/LoginDedicated';
// Other
import NotFound from './NotFound/NotFound';
import NotFoundDedicated from './Pages/Standalone/NotFoundDedicated';
//import Error from './Pages/Error';
import Maintenance from './Pages/Maintenance';
import Construction from './Pages/Construction';

// Descargas
import DownloadsLibretos from './Pages/Downloads/Libretos';


//Acervo 
import Home from './Pages/Home';

//Perfil 
import Perfil from './Pages/Perfil';

//busqueda
import Search from './Pages/Search';
import GlobalSearch from './Pages/GlobalSearch';

//notificaciones
import Notifications from './Pages/Users/Notifications/Notifications'

//Reportes
import Favoritos from './Pages/Reportes/Favoritos';
import Prospectos from './Pages/Reportes/Prospectos';
import Proyectos from './Pages/Reportes/Proyectos';
import ComposicionAcervo from './Pages/Reportes/ComposicionAcervo';
import BitacoraMovimientos from './Pages/Reportes/BitacoraMovimientos';
import Consultas from './Pages/Reportes/Consultas';
import UsuariosReporte from './Pages/Reportes/Usuarios';

//Configuracion
import PerfilesYPrivilegios from './Pages/Configuration/Privilegios';
import ObrasLiterarias from './Pages/Configuration/ObrasLiterarias';
import FileViewer from './Pages/Configuration/ObrasLiterarias/fileIndex'; 
import ConfigureNotifications from './Pages/Configuration/ConfigureNotifications';
import Usuarios from './Pages/Configuration/User';
import Parametros from './Pages/Configuration/Parameters';

//Catálogos
import PaisOrigen from './Pages/Catalogos/PaisOrigen';
import fuente from './Pages/Catalogos/Fuente';
import tematica from './Pages/Catalogos/Tematica';
import audiencia from './Pages/Catalogos/Audiencia';
import ambiente from './Pages/Catalogos/Ambiente';
import productores from './Pages/Catalogos/Productores';
import idioma from './Pages/Catalogos/Idioma';
import generos from './Pages/Catalogos/Generos';
import formato from './Pages/Catalogos/Formato';
import motivacion from './Pages/Catalogos/Motivacion';
import vigencia from './Pages/Catalogos/Vigencia';
import autores_adaptadores from './Pages/Catalogos/AutoresAdaptadores';
import duracion_libretos from './Pages/Catalogos/DuracionLibretos';
import HorariosSugeridos from './Pages/Catalogos/HorariosSugeridos';
import subgenero from './Pages/Catalogos/Subgenero';
import ventana from './Pages/Catalogos/Ventana';
import temporalidad from './Pages/Catalogos/Temporalidad';
import tipo_contrato from './Pages/Catalogos/TipoContrato';
import TerritoriosRestringidos from './Pages/Catalogos/TerritoriosRestringidos';
import rol_escritor from './Pages/Catalogos/Escritor';
import ControlMateriales from './Pages/Reportes/ControlMateriales';
import ManejoTiempo from './Pages/Catalogos/ManejoTiempo';
import NivelSocioeconomico from './Pages/Catalogos/NivelSocioeconomico';
import NumeroLineas from './Pages/Catalogos/NumeroLineas';
import TipoRomance from './Pages/Catalogos/TipoRomance';
import TrayectoriaProtagonista from './Pages/Catalogos/TrayectoriaProtagonista';

const routes = [{
  component: App,
  routes: [
    {
      component: LoginDedicated,
      path: '/',
      exact: true,
    },
    {
      component: Outer,
      path: '/login',
      routes: [
        {
          path: '/login',
          component: Login,
          exact: true,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    {
      component: Outer,
      path: '/lock-screen',
      routes: [
        {
          path: '/lock-screen',
          component: LockScreen,
          exact: true,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    {
      component: Outer,
      path: '/maintenance',
      routes: [
        {
          path: '/maintenance',
          component: Maintenance,
          exact: true,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/profile',
      routes: [
        {
          path: '/profile',
          component: Perfil,
          exact: true,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/acervo',
      routes: [
        {
          path: '/acervo',
          component: Home,
          exact: true,
        },
        {
          path: '/acervo/search-category',
          component: Search
        },
        {
          path: '/acervo/search-result',
          component: GlobalSearch
        },
        {
          path: '/acervo/dashboard',
          component: GlobalSearch
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/settings',
      routes: [
        {
          path: '/settings',
          component: Construction,
          exact: true,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/notifications',
      routes: [
        {
          path: '/notifications',
          component: Notifications,
          exact: true,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    //Reports Pages
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/reporte',
      routes: [
        /*{
          path: '/reporte',
          component: BlankPage,
          exact: true,
        },*/
        {
          path: '/reporte/prospectos',
          component: Prospectos,
          exact: true,
        },
        {
          path: '/reporte/proyectos',
          component: Proyectos,
          exact: true,
        },
        {
          path: '/reporte/favoritos',
          component: Favoritos,
          exact: true,
        },
        {
          path: '/reporte/composicion-acervo',
          component: ComposicionAcervo,
          exact: true,
        },
        {
          path: '/reporte/bitacora-movimientos',
          component: BitacoraMovimientos,
          exact: true,
        },
        {
          path: '/reporte/consultas',
          component: Consultas,
          exact: true,
        },
        {
          path: '/reporte/usuarios',
          component: UsuariosReporte,
          exact: true,
        },
        {
          path: '/reporte/controlmateriales',
          component: ControlMateriales,
          exact: true,
        },
        /*{
          path:'/reporte/fechas-vencimiento',
          component: FechasVencimiento,
          exact: true,
        },*/
        {
          path: '/reporte/',
          component: Error,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    //Catálogos Pages
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/catalogos',
      routes: [
        {
          path: '/catalogos/horarios-sugeridos',
          component: HorariosSugeridos,
          exact: true,
        },
        {
          path: '/catalogos/territorios-restringidos',
          component: TerritoriosRestringidos,
          exact: true,
        },
        {
          path: '/catalogos/pais-de-origen',
          component: PaisOrigen,
          exact: true,
        },
        {
          path: '/catalogos/fuente',
          component: fuente,
          exact: true,
        },
        {
          path: '/catalogos/tematica',
          component: tematica,
          exact: true,
        },
        {
          path: '/catalogos/audiencia',
          component: audiencia,
          exact: true,
        },
        {
          path: '/catalogos/ambiente',
          component: ambiente,
          exact: true,
        },
        {
          path: '/catalogos/productores',
          component: productores,
          exact: true,
        },
        {
          path: '/catalogos/idioma',
          component: idioma,
          exact: true,
        },
        {
          path: '/catalogos/generos',
          component: generos,
          exact: true,
        },
        {
          path: '/catalogos/formato',
          component: formato,
          exact: true,
        },
        {
          path: '/catalogos/motivacion',
          component: motivacion,
          exact: true,
        },
        {
          path: '/catalogos/vigencia',
          component: vigencia,
          exact: true,
        },
        {
          path: '/catalogos/autores-adaptadores',
          component: autores_adaptadores,
          exact: true,
        },
        {
          path: '/catalogos/duracion-de-libretos',
          component: duracion_libretos,
          exact: true,
        },
        {
          path: '/catalogos/subgenero',
          component: subgenero,
          exact: true,
        },
        {
          path: '/catalogos/ventana',
          component: ventana,
          exact: true,
        },
        {
          path: '/catalogos/temporalidad',
          component: temporalidad,
          exact: true,
        },
        {
          path: '/catalogos/rol-escritor',
          component: rol_escritor,
          exact: true,
        },
        {
          path: '/catalogos/tipo-de-contrato',
          component: tipo_contrato,
          exact: true,
        },
        {
          path: '/catalogos/manejo-tiempo/',
          component: ManejoTiempo,
          exact: true
        },
        {
          path: '/catalogos/nivel-socioeconomico/',
          component: NivelSocioeconomico,
          exact: true
        },
        {
          path: '/catalogos/num-lineas/',
          component: NumeroLineas,
          exact: true
        },
        {
          path: '/catalogos/tipo-romance/',
          component: TipoRomance,
          exact: true
        },
        {
          path: '/catalogos/trayectoria-protagonista/',
          component: TrayectoriaProtagonista,
          exact: true
        },

        /*{
          path:'/catalogos/empresa-proveedores',
          component: empresa_proveedores,
          exact: true,
        },*/
        {
          path: '/catalogos/',
          component: Error,
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    },
    //Configuracion
    {
      component: withSession(Dashboard), //AHDA 17/05/2019
      path: '/configuracion',
      routes: [
        {
          path: '/configuracion/perfiles-y-privilegios',
          component: PerfilesYPrivilegios,
          exact: true,
        },
        {
          path: '/configuracion/users',
          component: Usuarios,
          exact: true,
        },
        {
          path: '/configuracion/obras-literarias',
          component: ObrasLiterarias,
          exact: true,
        },
        {
          path: '/configuracion/parameters',
          component: Parametros,
          exact: true,
        },
        {
          path: '/configuracion/configuracion-de-notificaciones',
          component: ConfigureNotifications,
          exact: true,
        }
      ]
    },
    {
      component: OuterEmpty, 
      path: '/file',
      routes: [
        {
          path: '/file',
          component: FileViewer,
          exact: true,
        },
        {
          path: '*',
          component: NotFoundDedicated
        }
      ]
    },
    // Downloads
    //AHDA 10/04/2019
    //Changed route downloads to shared-material
    {
      component: withSession(OuterEmpty), //AHDA 17/05/2019
      path: '/shared-material',
      routes: [
        {
          path: '/shared-material/:id',
          component: DownloadsLibretos,
          exact: true,
        },
        {
          path: '*',
          component: NotFoundDedicated
        }
      ]
    }, 
    {
      path: '*',
      component: NotFoundDedicated
    },
  ]
}];

export default routes;
