import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import styles from './../reportes-jss'

//Filtros
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import Loader from '../../../../components/Loader/Loader';
import { textLabelsEs } from '../../../../utils/tableTexts';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

class ComposicionAcervo extends React.Component {
  state = {
    //filters
    accion: '',
    fechaInicio: null,
    fechaFin: null,
    pais: 0,
    empresa: 0,
    tipoObra: 0,
    genero: 0,
    producida: 0,
    formato: 0,
    vigencia: 0,
    fuente: 0,
    destino: 0,
    //Table
    columns: [],
    data: [],
    dataCsv: [], //AHDA 27/05/2019
    //Catalogos
    countries: [],
    business: [],
    genders: [],
    formats: [],
    vigencias: [],
    sources: [],
    results: 0,
    isLoading: true
  }

  //Dates
  handleDateChange = date => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = date => {
    this.setState({ fechaFin: date });
  };

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //AHDA 18/02/2019
  handleClearFilters = () => {

    this.setState({
      accion: '',
      fechaInicio: null,
      fechaFin: null,
      pais: 0,
      empresa: 0,
      tipoObra: 0,
      genero: 0,
      producida: 0,
      formato: 0,
      vigencia: 0,
      fuente: 0,
      destino: 0,
      isLoading: true
    })

    const request = {
      start_date: "0",
      end_date: "0",
      country: 0,
      company: 0,
      play_type: "0",
      genre: 0,
      produced: "0",
      format: 0,
      validity: 0,
      source: 0,
      destiny: "0"
    }
    this.handleFetch(request);
  }

  //Download csv
  handleDownload = (data) => {
    const {lang} = this.context;
    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'acervo',
      screen: 'reporteComposicionAcervo'
    }
    apiPOST('/audit/add', body);

    //AHDA 09/05/2019
    //Bus, Excel gap
    const dataCsv = [[
      lang.reports.title, lang.reports.authors, lang.reports.country, lang.reports.compProv, lang.reports.playType,
      lang.reports.genre, lang.reports.produced, lang.reports.producer, lang.reports.format, lang.reports.validity,
      lang.reports.purchaseDate, lang.reports.source, lang.reports.destiny, lang.reports.platform, lang.reports.productionYear,
      lang.reports.audience, lang.reports.chapterNumber, lang.reports.englishTitle, lang.reports.otherTitle
    ]]

    for (const x in data) {
      const object = []
      object.push(data[x])
      //object.unshift(" ");
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "ComposicionAcervoLiterario.csv";
    download.click();

  }

  //Llamada de busqueda
  searchs = () => {
    this.setState({ isLoading: true })
    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin)

    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      end_date: ff === 0 ? 0 : ff.getDate() + "/" + (ff.getMonth() + 1) + "/" + ff.getFullYear(),
      country: this.state.pais,
      company: this.state.empresa,
      play_type: this.state.tipoObra,
      genre: this.state.genero,
      produced: this.state.producida,
      format: this.state.formato,
      validity: this.state.vigencia,
      source: this.state.fuente,
      destiny: this.state.destino,
    }

    this.handleFetch(request);
  }

  componentDidMount() {
    const { lang } = this.context;
    this.setState({
      columns: [
        lang.reports.title, lang.reports.authors, lang.reports.country, lang.reports.compProv, lang.reports.playType,
        lang.reports.genre, lang.reports.produced, lang.reports.producer, lang.reports.format, lang.reports.validity,
        lang.reports.purchaseDate, lang.reports.source, lang.reports.destiny, lang.reports.platform, lang.reports.productionYear,
        lang.reports.audience, lang.reports.chapterNumber, lang.reports.englishTitle, lang.reports.otherTitle
      ]
    });

    //Catalogo paises
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 3 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ countries: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo empresas
    //AHDA 16/05/2019: Added valdation for refreshing
    //apiPOST('/catalogue/get',{idCatalogo: 7 }).then(
    apiPOST('/catalogue/get', { idCatalogo: 7 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ business: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo generos
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 4 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ genders: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo Nivel Socioeconomico
    apiPOST('/catalogue/get', { idCatalogo: 26 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ genders: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo Número de Líneas argumentales
    apiPOST('/catalogue/get', { idCatalogo: 27 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ genders: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo Manejo del Tiempo
    apiPOST('/catalogue/get', { idCatalogo: 28 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ genders: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo Tipo de Romance
    apiPOST('/catalogue/get', { idCatalogo: 29 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ genders: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo Trayectoria del Protagonista
    apiPOST('/catalogue/get', { idCatalogo: 30 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ genders: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo formatos
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 9 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ formats: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo vigencias
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 10 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ vigencias: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Catalogo vigencias
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 11 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ sources: response.data })
        } else {
          this.setState({ openError: true });
        }
      }
    ).catch(
      error => {
        console.log("Error in catalogue: ", error); 
      }
    );

    //Carga inicial
    const request = {
      start_date: "0",
      end_date: "0",
      country: 0,
      company: 0,
      play_type: "0",
      genre: 0,
      produced: "0",
      format: 0,
      validity: 0,
      source: 0,
      destiny: "0"
    }

    this.handleFetch(request);
  }

  //AHDA 18/02/2019
  handleFetch = (request) => {
    apiPOST('/reports/getAcervoComposition', request).then(
      response => {
        if (response.status === 200) {

          //AHDA 27/05/2019
          const result = [], resultCsv = [];
          for (const x in response.data) {
            var obj = [], objCsv = [];
            
            //AHDA 29/04/2019
            //Validation for empty values 
            const auxObj = response.data[x]; 
            obj.push(auxObj.title ? auxObj.title : "-");
            obj.push(auxObj.authors_work ? auxObj.authors_work : (auxObj.authors?auxObj.authors:"-"));
            obj.push(auxObj.country ? auxObj.country : "-");
            obj.push(auxObj.company ? auxObj.company : "-");
            obj.push(auxObj.play_type ? auxObj.play_type : "-");
            obj.push(auxObj.genre ? auxObj.genre : "-");
            obj.push((auxObj.produced === 1) ? "Sí" : "No");
            obj.push(auxObj.producer ? auxObj.producer : "-");
            obj.push(auxObj.format ? auxObj.format : "-");
            obj.push(auxObj.validity ? auxObj.validity : "-");
            obj.push(auxObj.purchase_date ? auxObj.purchase_date : "-");
            obj.push(auxObj.source ? auxObj.source : "-");
            obj.push(auxObj.destiny ? auxObj.destiny : "-");
            obj.push(auxObj.platform ? auxObj.platform : "-");
            obj.push(auxObj.anio_produccion ? auxObj.anio_produccion : "-");
            obj.push(auxObj.audience ? auxObj.audience : "-");
            obj.push(auxObj.total_chapters ? auxObj.total_chapters : "-");
            obj.push(auxObj.english_title ? auxObj.english_title : "-");
            obj.push(auxObj.other_title ? auxObj.other_title : "-");
            
            //AHDA 27/05/2019: Data for csv
            objCsv = [...obj];
            objCsv[1] = objCsv[1].replace(/,/g, ';'); //Authors
            objCsv[7] = objCsv[7].replace(/,/g, ';'); //Producers

            result.push(obj);
            resultCsv.push(objCsv); //AHDA 27/05/2019
          }
          this.setState({ data: result, dataCsv: resultCsv, isLoading: false, results: response.data.length })
        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }).catch(error => {
        this.setState({ openError: true, isLoading: false });
      });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    //AHDA 29/04/2019
    //Changed filterType
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.download} placement="bottom">
                <IconButton aria-label="download" onClick={() => this.handleDownload(this.state.dataCsv)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      },
      customSearch: (searchQuery, currentRow) => {
        let isFound = false;
        const str = searchQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        currentRow.forEach(col => {
          if (col && col.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(str) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      }
    };
    return (
      <div className="marginTopLogo">
        <LanguageConsumer>
          {
            ({ lang }) =>
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={6} sm={3} md={2}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          // helperText={lang.reports.evDateEnter}
                          helperText={lang.reports.startDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          // helperText={lang.reports.compDateEnter}
                          helperText={lang.reports.endDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaFin}
                          onChange={this.handleDateChangeFin}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          minDate={this.state.fechaInicio}
                          disabled={this.state.fechaInicio == null ? true : false}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.pais} onChange={this.handleChange} name="pais" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.countries.map((country, idx) =>
                              (
                                <MenuItem value={country.value} key={`countrie${idx}`}>{country.data}</MenuItem>
                              ))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.country}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.empresa} onChange={this.handleChange} name="empresa" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.business.map((busine, idx) =>
                              (
                                <MenuItem value={busine.value} key={`busine${idx}`}>{busine.data}</MenuItem>
                              ))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.compProv}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.tipoObra} onChange={this.handleChange} name="tipoObra" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>
                            {lang.reports.all}
                          </MenuItem>
                          <MenuItem value={"primigenia"}>{lang.reports.primal}</MenuItem>
                          <MenuItem value={"adaptacion"}>{lang.reports.adaptation}</MenuItem>
                        </Select>
                        <FormHelperText>{lang.reports.playType}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.genero} onChange={this.handleChange} name="genero" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.genders.map((gender, idx) =>
                              (
                                <MenuItem value={gender.value} key={`gender${idx}`}>{gender.data}</MenuItem>)
                            )
                          }
                        </Select>
                        <FormHelperText>{lang.reports.genre}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.producida} onChange={this.handleChange} name="producida" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          <MenuItem value={"true"}>{lang.reports.yes}</MenuItem>
                          <MenuItem value={"false"}>No</MenuItem>
                        </Select>
                        <FormHelperText>{lang.reports.produced}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.formato} onChange={this.handleChange} name="formato" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.formats.map((format, idx) =>
                              (
                                <MenuItem value={format.value} key={`format${idx}`}>{format.data}</MenuItem>
                              ))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.format}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.vigencia} onChange={this.handleChange} name="vigencia" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.vigencias.map((vigencia, idx) =>
                              (
                                <MenuItem value={vigencia.value} key={`vigencia${idx}`}>{vigencia.data}</MenuItem>
                              ))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.validity}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.fuente} onChange={this.handleChange} name="fuente" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.sources.map((source, idx) =>
                              (
                                <MenuItem value={source.value} key={`source${idx}`}>{source.data}</MenuItem>)
                            )
                          }
                        </Select>
                        <FormHelperText>{lang.reports.source}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.destino} onChange={this.handleChange} name="destino" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          <MenuItem value={"ventas"}>{lang.reports.sales}</MenuItem>
                          <MenuItem value={"produccion"}>{lang.reports.production}</MenuItem>
                        </Select>
                        <FormHelperText>{lang.reports.destiny}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={12}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.searchs.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                  </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.bitacoraMov}>
                  {
                    this.state.isLoading ?
                      <Loader text={"Cargando"} /> :
                      <MUIDataTable
                        title={`${lang.reports.repAcervoComposition} (${this.state.results} ${lang.reports.results})`}
                        data={data}
                        columns={columns}
                        options={options} />
                  }
                </div>
              </PapperBlock>
          }
        </LanguageConsumer>
      </div>
    );
  }
}

ComposicionAcervo.contextType = LanguageCxt;

export default withStyles(styles)(ComposicionAcervo);