import defaultImage from '../../../static/images/default.png';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    rootSlider: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    item: {
        textAlign: 'center',
        height: '380px',
        '& >img': {
            // margin: '10px auto',
            border: 'none',
            height: '100%',
            objectFit: 'contain'
        },
    },
    //ESTILOS TEMPORALES, SOLO PARA VISUALIZACION (no deja de funcionar si le mueves)
    itemVideo: {
        background: `url(${defaultImage}) no-repeat center`,
        backgroundSize: 'cover',
        minHeight: '260px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            //margin: 'auto',
            border: 'none',
            cursor: 'pointer',
        },
    },
    absolute: {
        position: 'absolute',
    },
    contentVideos: {
        display: 'flex',
    },
    contentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        margin: theme.spacing.unit,
    },
    appBar: {
        position: 'relative',
        background:
            'linear-gradient(93deg, #F12065 0.18%, #F32758 50.82%, #FE5503 101.46%)',
    },
    flex: {
        flex: 1,
    },
    detailContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        margin: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1080,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 960,
            paddingTop: 40,
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
            paddingInline: '8px',
        },

        '& .container.detalleSlider': {
            paddingInline: '4px',

            '& .slick-dots': {
                position: 'initial',
            }
        }
    },

    detailWrap: {
        padding: '10px 30px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    title: {
        //marginBottom: 30,
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 600,
        textTransform: 'uppercase',
        '& small': {
            fontWeight: 300,
            marginLeft: 5,
            textTransform: 'capitalize',
        },
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0',
        //padding: '8px 12px',
        '& > *': {
            marginRight: 10,
        },
        '& > span': {
            lineHeight: 1,
        }
    },
    ratting: {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        marginBottom: 20,
    },
    btnArea: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        background: theme.palette.grey[100],
        padding: '10px 20px',
    },
    quantity: {
        width: 40,
        marginRight: 40,
        marginLeft: 10,
        '& input': {
            textAlign: 'right',
        },
    },
    desc: {
        padding: '10px 0',
    },
    heading: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 'large',
        marginLeft: '20px !important',
    },
    iconLeft: {
        color: theme.palette.primary.main,
        position: 'absolute',
        left: 10,
        top: 12,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        height: '100%',
        color: theme.palette.text.secondary,
    },
    labelTitle: {
        color: theme.palette.primary.main,
        //display: 'inline-block',
    },
    inputBlock: {
        //margin: theme.spacing.unit,
        width: '100%',
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    pt2: {
        paddingTop: '10px !important',
    },
    paddingTopLabel: {
        paddingTop: '15px',
    },
    divider: {
        marginTop: 6,
        marginBottom: 6,
    },
    formControl: {
        margin: theme.spacing.unit,
        marginLeft: 0,
        marginTop: 0,
        minWidth: 200,
    },
    heightMax: {
        maxHeight: '400px',
        overflow: 'auto',
    },
    alignButton: {
        textAlign: 'right',
        padding: '0 0 1rem 0',
    },
    removePadding: {
        padding: 'unset',
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center !important',
    },
    colorSecondaryText: {
        color: '#a7a7a7',
    },
    // JCMY 21/06/2019
    cursorPointer: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
    justify: {
        textAlign: 'justify',
    },
    pNoMargin: {
        '& p': {
            margin: 0,
        },
    },
    gradientBackground: {
        background:
            'linear-gradient(93deg, #F12065 0.18%, #F32758 50.82%, #FE5503 101.46%)',
    },
    chipTipo: {
        color: '#fff',
        fontSize: 12,
        height: 24,
        minWidth: 100,
        textAlign: 'center',
        width: 'auto',
        '& span': {
            paddingLeft: 8,
            paddingRight: 8,
            whiteSpace: 'break-spaces',
            lineHeight: '1 !important'
        },
    },
    chipTipoBorder: {
        background: 'none',
        borderWidth: 2,
        borderStyle: 'solid',
        fontSize: 12,
        height: 24,
        lineHeight: 1,
        minWidth: 100,
        textAlign: 'center',
        width: 'auto',
    },
    chipVigente: {
        // #f12065
        background: theme.palette.primary.main,
    },
    chipPorVencer: {
        // #fcca15
        background: theme.palette.secondary.main,
    },
    chipVencida: {
        // #5b6066
        background: theme.palette.graydefault,
    },
    chipPrimigenia: {
        // #5b6066
        background: theme.palette.warning.main,
    },
    chipAdaptacion: {
        // #5b6066
        background: '#f68e5c',
    },
    chipProduccionTelevisa: {
        borderColor: '#fa6a0c',
    },
    chipProduccionExtranjera: {
        borderColor: '#e05d07',
    },
    chipNoProducida: {
        borderColor: '#fcca15',
    },
    chipInedita: {
        borderColor: '#c89e08',
    },
    chipDefault: {
        background: 'gray',
    },
    exportButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '10px',
        padding: '0 !important',

        [theme.breakpoints.down('md')]: {
            position: 'relative',
            zIndex: 1,
            paddingRight: `${theme.spacing.unit * 2}px !important`,
            paddingTop: `${theme.spacing.unit * 2}px !important`,
        },
    },
    optionLabel: {
        display: 'inline-flex',
        alignItems: 'center',
        lineHeight: 0,
        marginRight: theme.spacing.unit,
    },
    optionLabel80: {
        minWidth: 80,
    },
    optionValue: {
        display: 'inline-flex',
        alignItems: 'center',
        flex: 1,
        padding: 0,
        paddingLeft: 5,
        marginRight: theme.spacing.unit * 2,
        '& *[class*="MuiIconButton-label"]': {
            justifyContent: 'flex-start',
        },
    },
    optionItem: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
    },
    optionDescription: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '30%',
    },
    optionContainer: {
        display: 'flex',
        flexFlow: ' row nowrap',
        alignItems: 'center'
    },
    header: {
        '& span': {
            color: theme.palette.primary.main,
        },
    },
    chipContainer: {
        height: 0,
        position: 'relative',
        marginLeft: 'auto',
        width: 100,
        top: 8,
        right: 8,
        '& > *': {
            position: 'absolute',
            right: 0,
        },
    },
    evaluacionPanel: {
        marginBottom: theme.spacing.unit * 2,
    },
    evaluacionHeading: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    evaluacionFrame: {
        width: '100%',
        '& iframe': {
            width: '100% !important',
        },
    },
    evaluacionDetails: {
        display: 'block',
    },
    evaluacionData: {
        marginBottom: theme.spacing.unit,
    },
    tabsContainer: {
        '& button': {
            opacity: 0.65
        },
        '& *[aria-selected=true]': {
            opacity: 1,
            transform: 'scale(1.1)'
        },
        '& *[role=tablist]': {
            overflowY: 'hidden',
        }
    },
    checkboxOption: {
        display: 'inline-block',
        minWidth: 100,
    },
    primaryRadio: {
        color: "#F12065 !important",
        '& svg': {
            borderRadius: '50%',
        },
        '&$checked': {
            color: "#F12065 !important",
        },
    },
    alignRadioBtn: {
        display: "flex",
        flexFlow: "row wrap"
    }
});

export default styles;
