import React from 'react';
import red from '@material-ui/core/colors/red';
import SelectNew from '../../components/Forms/Select';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { FormControl, InputLabel, Select, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from '../../components/Works/nuevaObra-jss';

const red300 = red['500'];

const style = {
    fontSize: '12px',
    color: red300,
    marginTop: '6px',
};

class SelectValidatorCustom extends ValidatorComponent {

    componentDidUpdate(prevProps) {
        if (prevProps.valid !== this.props.valid) {
            this.setState({
                isValid: this.props.valid,
            });
        }
    }

    render() {
        const { classes, valid, ...rest } = this.props;

        return (
            <FormControl className={classNames(classes.field, classes.w100)}>
                
                <SelectNew
                    {...rest}
                    ref={(r) => { this.input = r; }}
                    classes={classes}
                    error={!this.state.isValid}
                />
                {this.errorText()}
            </FormControl>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={style}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default withStyles(styles)(SelectValidatorCustom);