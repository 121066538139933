import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Avatar from '@material-ui/core/Avatar';
import MainMenu from './MainMenu';
import styles from './sidebar-jss';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { S3Image } from 'aws-amplify-react';
import {LanguageConsumer} from './../../language/LanguageContext'; 

//Default user img 
//import ppboy from '../../../static/images/pp_boy.svg'; 

//Services 
import { logOut } from '../../services/cognito_service'; 
import { returnCurrentUser } from '../../services/service_base';

const MenuContent = props => {
  const {
    classes,
    //turnDarker,
    drawerPaper,
    toggleDrawerOpen,
    loadTransition, 
    handleClick
  } = props;
  
  //TODO: validate current user 
  //const user = JSON.parse(localStorage.getItem('current_user')); 
  
  const user = returnCurrentUser();
  
  return (
    <LanguageConsumer>
      {
        ({lang}) => 
        <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : '')}>
          <div className={classes.drawerHeader}>
            {/* <div className={classNames(classes.brand, classes.brandBar, turnDarker && classes.darker)}>
              <img src={logo} alt={brand.name} />
              <h3>{brand.name}</h3>
            </div> */}
            <div className={classNames(classes.profile, classes.user)}>
          {/* CH 27/06/2019 Se pone avatar con el hover en el menu */}
              <Avatar
                alt=""
                src={user ? "" : user.portrait ? "" : "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"}
                className={classNames(classes.avatar, classes.bigAvatar, classes.unsetFlex)}
              >
                {
                  !user ? "" : !user.portrait ? "" : <S3Image theme={{ photoImg: { width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={user.portrait}/> 
                }
              </Avatar>
            </div>
              <div className={classes.usuario}>
                <h4 className={classes.ellipsisText}>{`${ user ? user.nombre : ""} ${user ? user.apellidos : ""}`}</h4>
                <span className={classes.ellipsisText}>{user? user.asigned_profile : "-"}</span>
              </div>
            </div>
          <div className={classes.menuContainer}>
            <MainMenu loadTransition={loadTransition} toggleDrawerOpen={toggleDrawerOpen} drawerPaper={drawerPaper}/>
            {/* CH 10/05/2019
            Se cambian estilos */}
            <ListItem button className={classNames(classes.nested, "itemText")} onClick={handleClick} onTouchEnd={handleClick}>
                <ListItemIcon>
                  <Icon className={classes.icon}>lock</Icon>
                </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary={lang.common.logout} />
            </ListItem>
          </div>
        </div>
      }
    </LanguageConsumer>
    
  );
};

MenuContent.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  turnDarker: PropTypes.bool,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
};

MenuContent.defaultProps = {
  turnDarker: false,
  handleClick: () => {logOut();}
};

const MenuContentStyle = withStyles(styles)(MenuContent);

class Sidebar extends React.Component {
  state = {
    anchor: 'left'
  };

  render() {
    const { anchor } = this.state;
    const {
      classes,
      open,
      toggleDrawerOpen,
      loadTransition,
      turnDarker
    } = this.props;
    return (
      <Fragment>
        <Hidden lgUp>
          <SwipeableDrawer
            onClose={toggleDrawerOpen}
            onOpen={toggleDrawerOpen}
            open={!open}
            anchor={anchor}
          >
            <MenuContentStyle drawerPaper toggleDrawerOpen={toggleDrawerOpen} loadTransition={loadTransition} />
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            variant="permanent"
            onClose={toggleDrawerOpen}
            classes={{
              paper: classNames(classes.drawer, classes.drawerPaper, !open ? classes.drawerPaperClose : ''),
            }}
            open={open}
            anchor={anchor}
          >
            <MenuContentStyle drawerPaper={open} turnDarker={turnDarker} loadTransition={loadTransition} />
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  turnDarker: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Sidebar);
