import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Book from '@material-ui/icons/Book';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Language from '@material-ui/icons/Language';
import { LanguageConsumer, LanguageCxt } from './../../language/LanguageContext';
import { InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialVideos from './../ProductDetail/MaterialVideos';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification';
import Modal from '@material-ui/core/Modal';
import MediaModalSlider from './../ProductDetail/MediaModalSlider';
import RelatedWorks from './../ProductDetail/RelatedWorks';
import Loader from './../Loader/Loader';
import ImageCarousel from './imageCarousel';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import Hidden from '@material-ui/core/Hidden';
import draftToHtml from 'draftjs-to-html';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
//Styles  
import '../../styles/components/vendors/slick-carousel/slick-carousel.css';
import '../../styles/components/vendors/slick-carousel/slick.css';
import '../../styles/components/vendors/slick-carousel/slick-theme.css';
import styles from './product-jss';
import './productDetail.css';
import iconPlay from '../../../static/images/iconplay.png';

//Table  
import DetailTable from '../Gallery/DetailTable';

//Tabs  
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//Acoordion  
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import defaultImage from '../../../static/images/default.png';
import radionovelaImage from '../../../static/images/RADIONOVELA.jpg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';

//AHDA 09/04/2019  
//Added dependency for creating folder   
//Services  
import { apiPOST, returnCurrentUserId, returnCurrentUser } from '../../services/service_base';
import { getFile, createFolderBase, getMultipleFiles } from '../../services/storage';
import { sendNotificationToSpecific, sendNotification } from '../../services/notifications';
import { markAsLockEnable, markAsProjectEnable, updateProjects, updateProspects } from '../../helpers/workMarkedHelper';

// import GoogleDocsViewer from 'react-google-docs-viewer';
import { S3Image } from 'aws-amplify-react';
import { Storage } from 'aws-amplify';

//AHDA 02/05/2019 
//Added dependencies for scripts table 
import TableElegirLibretto from '../../routes/Pages/Users/Notifications/TableElegirLibretto';
import { getFileTitle, getCleanTitle, getRedirectUrl } from '../../helpers/general';
import { zipFile } from '../../services/zip_services';
import VideoThumbnail from 'react-video-thumbnail';
import { getChipClassNames } from './product-utils';
import { Button as AtomButton } from '../../atoms';
import TabVigencias from './TabVigencias';
import TabEquiposLiterarios from './TabEquiposLiterarios';
import TabEvaluacion from './TabEvaluacion';

import ls from 'local-storage';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { CloudDownload } from '@material-ui/icons';
import LoaderFull from '../Loader/LoaderFull';
import TabMoreInfo from './TabMoreInfo';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

//Tab content  
function TabContainer(props) {
  return (
    <div style={{ padding: '20px' }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ProductDetail extends React.Component {

  //AHDA 02/05/2019 
  //Added Scripts  
  state = {
    dense: false,
    secondary: false,
    value: null,
    productores: [],
    productor: this.props.detailWork.idUserBlocked !== null ? this.props.detailWork.idUserBlocked.split(",") : [],
    showButtons: this.props.detailWork.idUserBlocked !== null ? 1 : 0,
    evaluation: "",
    //Notification  
    open: false,
    variant: 'null',
    message: 'null',
    //Modal  
    openModal: false,
    selectedVideo: [],
    //Scripts  
    rawScripts: [],
    scripts: [],
    auxMultimedia: [],
    catalogs: ls.get("catalogue_list"),
    uploadProgress: null,
    auxMoreInfo: this.props.detailWork.moreInfo
  }

  //For inputs  
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickView = (file) => {
    if (file) {
      const auxUrl = encodeURIComponent(file);
      window.open(`/file?doc=${auxUrl}`, "_blank");
    }
  }

  handleDownload = async (file) => {
    this.setState({ uploadProgress: 0.5 })
    getFile(file, 'public', null, this.updateProgress).then(response => {
      window.open(response, "_blank");
    }).catch(() => {
      this.setState({
        open: true,
        variant: 'error',
        message: 'El archivo no está disponible',
      })
    })
      .finally(() => {
        this.setState({ uploadProgress: null })
      })
  };

  updateProgress = (progress) => {
    if (!this.state.uploadProgress || progress > this.state.uploadProgress)
      this.setState({ uploadProgress: progress.toFixed(2) })
  }

  //Handle tabs change   
  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  //For notifications  
  handleClose = () => {
    this.setState({ open: false });
  };

  handleNotifications = () => {
    this.setState({ open: true, variant: 'info', message: 'Sólo puedes tener como máximo 3 obras marcadas como Prospecto' })
  }

  //For video modal   
  handleCloseModal = () => {
    this.setState({ openModal: false });
  }

  handleOpenModal = (selectedVideo) => {
    this.setState({ openModal: true, selectedVideo: selectedVideo })
  }


  unlockWork = () => {
    let auxUser = returnCurrentUserId()
    const request = {
      users: [{ assignedUser: auxUser, isAdded: false }],
      idObra: this.props.detailContent.id_obras,
      allocatorId: auxUser,
      screen: this.props.screen
    }

    apiPOST('/obras/markAsProspect', request).then(
      () => {
        updateProspects(this.props.detailContent.id_obras, false);
        const auxUser = returnCurrentUser();
        const assigner = this.props.detailWork.moreInfo.assignedProspects.find(e => e.idObra === this.props.detailContent.id_obras).idAsignador;
        let bodyEmail = {
          type: "unlockWorkPropspectUser",
          work: {
            titulo_original: this.props.detailContent.titulo_original
          },
          user: {
            name: auxUser.nombre,
            last_name: auxUser.apellidos
          }
        }
        let bodyNotification = {
          notification_type: "unlockWorkPropspectUser",
          id_work: this.props.detailContent.id_obras,
          id_notification_type: 18,
          specifiedProductor: returnCurrentUserId()
        }
        sendNotificationToSpecific(18, bodyEmail, bodyNotification, assigner);
        this.setState({ open: true, isLoading: false, variant: 'success', message: this.context.lang.adminWork.prospecteUpdated });
        this.props.close();

      }
    ).catch(
      () => {
        this.setState({ open: true, isLoading: false, variant: 'error', message: this.context.lang.common.serviceError });
      }
    );
  }

  //AHDA 03/04/2019  
  //Method for request scripts  
  handleRequestScripts = (event) => {

    const auxUser = returnCurrentUser();

    //TODO: Delete shared when added Prefix   
    let bodyRequest = {
      id_user: returnCurrentUserId(),
      id_work: this.props.detailContent.id_obras,
      folder: `shared/${auxUser.usuario_cognito}`,
      screen: this.props.screen //AHDA 04/06/2019
    }

    //TODO: generate folder (call service)  
    apiPOST('/material/addrequest', bodyRequest).then(
      response => {
        if (response.status === 200) {

          //AHDA 09/04/2019  

          //TODO: Delete shared when added Prefix   
          createFolderBase(`shared/${auxUser.usuario_cognito}`, `In this folder you will find shared material with ${auxUser.usuario_cognito}`).then(
            response => { console.info(response); }
          ).catch(
            err => { console.error(err); }
          );

          //SendNotification  
          let bodyNotification = {
            notification_type: "scriptsAccessRequest",
            id_work: this.props.detailContent.id_obras,
            specifiedProductor: auxUser.id_usuario,
            id_notification_type: 10,
            id_specifiedRequest: response.data[0].request_id
          }

          let bodyEmail = {
            type: "scriptsAccessRequest",
            work: {
              titulo_original: this.props.detailContent.titulo_original
            },
            user: {
              name: auxUser.nombre,
              last_name: auxUser.apellidos
            }
          }

          sendNotification(10, bodyEmail, bodyNotification);

          //Show message  
          this.setState({ open: true, isLoading: false, variant: 'success', message: 'Se ha enviado la solicitud de acceso a los libretos' });

        } else {
          console.error("Error: ", response);
          this.setState({ open: true, isLoading: false, variant: 'error', message: 'No se pudo completar tu solicitud' });
        }
      }
    ).catch(
      err => {
        console.error("Error: ", err);
        this.setState({ open: true, isLoading: false, variant: 'error', message: 'No se pudo completar tu solicitud' });
      }
    )
  }

  //AHDA 02/05/2019 
  //Added methods for table functionality  
  // ------------------------------- Scripts Table  
  handleClickSelectFiles = (selectedRows) => {

    //Get selected rows  
    const auxSelectedRows = selectedRows.data;
    //Copy of raw scripts 
    const rawScripts = [...this.state.rawScripts];
    //Helper for filtering selected rows and generated urls 
    let auxMaterial = [], urls = [];

    //Get selected materials 
    for (let i in auxSelectedRows) {
      auxMaterial.push({
        key: rawScripts[auxSelectedRows[i].dataIndex].nombre,
        fileName: getFileTitle(rawScripts[auxSelectedRows[i].dataIndex].nombre)
      });
    }

    //Generate urls  
    getMultipleFiles(auxMaterial, 'public', 120).then(response => {
      urls = response;
      zipFile(`${getCleanTitle(this.props.detailWork.titulo_original)}_Libretos`, urls).then().catch(err => {
        this.setState({ open: true, variant: "error", message: "No se pudieron descargar los archivos" });
      });
    });
  }

  handleViewFile = (idx) => {

    let auxData = [...this.state.rawScripts];
    const item = auxData[idx];

    if (item) {
      const auxUrl = encodeURIComponent(item);
      window.open(`/file?doc=${auxUrl}`, "_blank");
    }
  }
  // ------------------------------- End Scripts Table  

  componentDidMount() {

    var data = { "idCatalog": 16 };

    apiPOST('/catalogue/getComplete', data, true).then(
      response => {

        if (response.status === 200) {

          this.setState({ productores: response.data })
        } else {
          this.setState({ openError: true });
        }

      }
    ).catch(
      error => {
        this.setState({ openError: true, isLoading: false });
      }
    );

    //AHDA 02/05/2019 
    //Added const  
    const { detailWork } = this.props;
    //Get evaluation url   
    if (detailWork.evaluacion) {
      getFile(detailWork.evaluacion).then(response => {
        this.setState({ evaluation: encodeURIComponent(response) });
      }).catch(err => {
        this.setState({ evaluation: "" });
      })
    }

    //AHDA 02/05/2019 
    //Added mapping for project scripts 
    if (detailWork.idUserBlocked) {
      if (detailWork.idUserBlocked.split(",").includes(returnCurrentUserId())) {
        if (detailWork.libretos.length > 0) {
          const auxLibretos = [...detailWork.libretos];
          let auxTotalScripts = [], auxRawTotalScripts = [];

          auxLibretos.map(l => {
            auxRawTotalScripts = auxRawTotalScripts.concat(l.libretos);
            return l;
          });

          for (let x in auxRawTotalScripts) {
            const item = [
              getFileTitle(auxRawTotalScripts[x].nombre), auxRawTotalScripts[x].idioma, auxRawTotalScripts[x].description, auxRawTotalScripts[x].amount, 'true'
            ]

            auxTotalScripts.push(item);
          }
          this.setState({ rawScripts: auxRawTotalScripts, scripts: auxTotalScripts });
        }
      }
    }

    let auxMultimedia = new Array();
    detailWork.multimedia.map(
      (item, index) => {
        Storage.get(item.url).then(
          response => {
            let o = {
              descripcion: item.descripcion,
              id_material_multimedia: item.id_material_multimedia,
              type: item.type,
              url: item.url,
              keyUrl: response
            }

            auxMultimedia.push(o)
          }
        ).then(() => {
          if (index === detailWork.multimedia.length - 1) {
            this.setState({ auxMultimedia: auxMultimedia })
          }
        })
      }
    );

    if (detailWork.evaluacion) {
      getFile(detailWork.evaluacion).then(response => {
        this.setState({ evaluation: encodeURIComponent(response) });
      }).catch(err => {
        this.setState({ evaluation: "" });
      })
    }

    let auxTotalScripts = [], auxRawTotalScripts = [];
    if (detailWork.idUserBlocked) {
      if (detailWork.idUserBlocked.split(",").includes(returnCurrentUserId())) {
        if (detailWork.libretos.length > 0) {
          const auxLibretos = [...detailWork.libretos];

          auxLibretos.map(l => {
            auxRawTotalScripts = auxRawTotalScripts.concat(l.libretos);
            return l;
          });

          for (let x in auxRawTotalScripts) {
            const item = [
              getFileTitle(auxRawTotalScripts[x].nombre), auxRawTotalScripts[x].idioma, auxRawTotalScripts[x].fecha, 'true'
            ]

            auxTotalScripts.push(item);
          }
        }
      }
    }

    this.setState({
      value: this.props.chkViewSinopsis ? 'synopsis' : null,
      rawScripts: auxRawTotalScripts,
      scripts: auxTotalScripts,
      productor: detailWork.idUserBlocked ? detailWork.idUserBlocked.split(",") : [],
      showButtons: detailWork.idUserBlocked ? 1 : 0
    });

  };

  //AHDA 02/05/2019 
  //Added lifecycle method for receiving props  
  componentWillReceiveProps(nextProps) {
    if (nextProps.detailWork) {
      if (nextProps.detailWork.id_obras !== this.props.detailWork.id_obras) {
        const { detailWork } = nextProps;

        //TODO: refresh evaluation  
        if (detailWork.evaluacion) {
          getFile(detailWork.evaluacion).then(response => {
            this.setState({ evaluation: encodeURIComponent(response) });
          }).catch(err => {
            this.setState({ evaluation: "" });
          })
        }

        let auxTotalScripts = [], auxRawTotalScripts = [];
        if (detailWork.idUserBlocked) {
          if (detailWork.idUserBlocked.split(",").includes(returnCurrentUserId())) {
            if (detailWork.libretos.length > 0) {
              const auxLibretos = [...detailWork.libretos];

              auxLibretos.map(l => {
                auxRawTotalScripts = auxRawTotalScripts.concat(l.libretos);
                return l;
              });

              for (let x in auxRawTotalScripts) {
                const item = [
                  getFileTitle(auxRawTotalScripts[x].nombre), auxRawTotalScripts[x].idioma, auxRawTotalScripts[x].fecha, 'true'
                ]

                auxTotalScripts.push(item);
              }
            }
          }
        }

        this.setState({
          rawScripts: auxRawTotalScripts,
          scripts: auxTotalScripts,
          productor: detailWork.idUserBlocked ? detailWork.idUserBlocked.split(",") : [],
          showButtons: detailWork.idUserBlocked ? 1 : 0
        });
      }
    }
  }

  addDefaultSrc(ev, url, index) {
    let event = ev.event;
    if (url) {
      Storage.get(url).then(
        response => {
          this.setState(
            {
              [`photo_${index}`]: response
            }
          )
        }
      ).catch(
        err => {
          console.error("error", err);
        }
      )
    } else {
      ev.target.src = defaultImage;
    }
  }

  convertContent = (data) => {
    let a = "";
    if (!data) {
      return "";
    }
    try {
      a = JSON.parse(`${data}`);
      let text = "";
      if (Array.isArray(a.blocks)) {
        a.blocks.forEach(b => (b.text) ? text += (b.text + "<br>") : null);
      }
      return text;
    } catch (error) {
      return data;
    }
  }

  handleDownloadFT = () => {
    const { lang } = this.context;
    const { detailWork, productor } = this.props;
    let tag1 = 18, tag2 = 18, tag3 = 40;
    const data = [];

    const doc = new jsPDF()
    doc.setFontSize(16);
    doc.setFontStyle("bold");
    doc.text(detailWork.titulo_original.toUpperCase(), 20, 20);
    doc.setFontSize(12);
    doc.text(detailWork.pais, 20, 30);

    if (detailWork.vigencias && !productor) {
      switch (detailWork.vigencias.type) {
        case 'Vigente':
          doc.setDrawColor(241, 32, 101);
          doc.setFillColor(241, 32, 101);
          break;
        case 'Por Vencer':
          doc.setDrawColor(252, 202, 21);
          doc.setFillColor(252, 202, 21);
          break;
        case 'Vencida':
        case 'Sin Derechos':
        case 'Hold Back':
          doc.setDrawColor(91, 96, 102);
          doc.setFillColor(91, 96, 102);
          break;
        default:
          doc.setDrawColor(241, 32, 101);
          doc.setFillColor(241, 32, 101);
          break;
      }
      doc.roundedRect(tag1, 34, (detailWork.vigencias.type.length * 3) + 5, 10, 5, 5, 'FD');
      doc.setTextColor(255, 255, 255);
      doc.text(detailWork.vigencias.type, tag1 + 5, 40);
      tag2 = tag1 + (detailWork.vigencias.type.length * 3) + 10;
    }
    if (detailWork.tipo_obra) {
      let typeName = lang.playDetail.adptation;
      if (detailWork.tipo_obra === "primigenia") {
        typeName = lang.playDetail.original;
        doc.setDrawColor(254, 85, 3);
        doc.setFillColor(254, 85, 3);
      } else {
        doc.setDrawColor(246, 142, 92);
        doc.setFillColor(246, 142, 92);
      }
      doc.roundedRect(tag2, 34, 32, 10, 5, 5, 'FD');
      doc.setTextColor(255, 255, 255);
      doc.text(typeName, tag2 + 5, 40);
      tag3 = tag2 + 35
    }
    if (detailWork.produccion) {
      switch (detailWork.produccion) {
        case "Producción Televisa":
        case "Producción Extranjera":
          doc.setDrawColor(254, 85, 3);
          break;
        case 'No producida':
          doc.setDrawColor(252, 202, 21);
          break;
        case 'Inédita':
          doc.setDrawColor(200, 158, 8);
          break;
        default:
          doc.setDrawColor(255, 255, 255);
          break;
      }
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(tag3, 34, (detailWork.produccion.length * 3), 10, 5, 5, 'FD');
      doc.setTextColor(0);
      doc.text(detailWork.produccion, tag3 + 5, 40);
    }

    {
      detailWork.tipo_obra === "adaptacion" &&
        data.push([lang.playDetail.originalWorkTitle, (detailWork.obra_primigenia ? detailWork.obra_primigenia : '')])
    }
    {
      detailWork.tipo_obra === "adaptacion" &&
        data.push([lang.playDetail.otherWorks, (detailWork.otherPrimigenia ? detailWork.otherPrimigenia.map(({ titulo_original }) => titulo_original).join(", ") : "")])
    }
    {
      (detailWork.tipo_obra === "adaptacion" || detailWork.producida === 1) &&
        data.push([lang.playDetail.otherVersions, (detailWork.version_titles ? detailWork.version_titles.map(({ titulo_original }) => titulo_original).join(", ") : "")])
    }
    {
      detailWork.producida === 1 &&
        data.push([lang.playDetail.proudctionYear, detailWork.anio_produccion])
    }
    data.push([lang.playDetail.chapters, (detailWork.numero_capitulos + " x " + (detailWork.duracion_capMin ? `${detailWork.duracion_capMin} min.` : '-'))])
    {
      detailWork.producida === 1 &&
        data.push([lang.playDetail.airedChapters, detailWork.ep_alAire + " x " + (detailWork.duracion_capProd ? `${detailWork.duracion_capProd} min.` : "-")])
    }
    data.push([lang.playDetail.authors, detailWork.autores])
    {
      detailWork.tipo_obra === "adaptacion" &&
        data.push([lang.playDetail.adapters, detailWork.adaptadores])
    }
    {
      detailWork.producida === 1 &&
        data.push([this.getCatalogName(16), detailWork.productores])
    }
    data.push([this.getCatalogName(8), detailWork.formato])
    data.push([this.getCatalogName(9), detailWork.ventana])
    data.push([this.getCatalogName(10), detailWork.audiencia])
    data.push([lang.playDetail.schedule, (detailWork.horario != null ? detailWork.horario + " hrs" : "")])
    data.push([this.getCatalogName(22), detailWork.horariosugerido ? detailWork.horariosugerido.map(e => e.descripcion).join(' ,') : ""])

    data.push([this.getCatalogName(5), detailWork.genero])
    data.push([this.getCatalogName(6), detailWork.subgenero])
    data.push([this.getCatalogName(7), detailWork.tematica])

    data.push([this.getCatalogName(11), detailWork.motivacion])
    data.push([this.getCatalogName(12), detailWork.temporalidad])
    data.push([this.getCatalogName(13), detailWork.ambiente])

    data.push([lang.playDetail.logline, detailWork.logline])

    doc.autoTable({
      theme: "plain",
      head: [["", ""]],
      body: data,
      startY: 40,
      margin: 20,
      styles: { overflow: "linebreak", cellWidth: "auto" },
      columnStyles: { 0: { cellWidth: 50, fontStyle: "bold" } },
    })

    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: [241, 32, 101], textColor: [255, 255, 255] },
      bodyStyles: { fillColor: [245, 245, 245], textColor: [0, 0, 0] }
    }, doc);
    doc.autoTable({
      head: [[lang.playDetail.shortSynopsis]],
      body: [[document.getElementById("sinopsis_corta").innerText]],
      startY: doc.lastAutoTable.finalY + 20,
      showHead: 'firstPage',
      margin: 20,
    })
    doc.autoTable({
      head: [[lang.playDetail.longSynopsis]],
      body: [[document.getElementById("sinopsis_larga").innerText]],
      startY: doc.lastAutoTable.finalY + 20,
      showHead: 'firstPage',
      margin: 20
    })

    const charactersData = [];
    detailWork.personajes.map((item, index) => {
      charactersData.push([
        {
          content: item.nombre,
          styles: {
            fontStyle: 'bold'
          }
        },
        {
          content: lang.playDetail.age + ": " + item.edad,
          styles: {
            textColor: [128, 128, 128]
          }
        }
      ]);
      charactersData.push([
        {
          content: lang.playDetail.characterPsychology,
          colSpan: 2,
          styles: {
            textColor: [241, 32, 101]
          }
        }
      ])
      charactersData.push([
        {
          content: item.psicologia,
          colSpan: 2
        }
      ])
      if (index + 1 < detailWork.personajes.length)
        charactersData.push([
          {
            content: "____________________________________________________________________________________",
            colSpan: 2,
            styles: {
              halign: 'center',
              textColor: [128, 128, 128]
            }
          }
        ])
    })

    doc.autoTable({
      theme: "plain",
      head: [[lang.playDetail.characters, ""]],
      body: charactersData,
      startY: doc.lastAutoTable.finalY + 20,
      showHead: 'firstPage',
      margin: 20
    })
    doc.save(`${detailWork.titulo_original} - ${lang.playDetail.resumeFile}.pdf`)
  }

  getCatalogName = (id) => {
    const catalogs = this.state.catalogs;
    if (catalogs) {
      const temp = catalogs.find(({ id_catalogue }) => id_catalogue === id)
      return temp ? temp.cat_name : "";
    } else {
      return "";
    }
  }

  render() {
    const {
      classes,
      open,
      close,
      detailWork,
      //isanchez 3.05.2019 
      detailContent,
      loading,
      canFavorite,
      canAddProspect,
      canApplyForMaterial,
      canAssignProyect,
      canEndWork,
      canShareMaterial,
      canUnlockWork,
      canViewWorkBlock,
      chkViewScripts,
      chkViewSinopsis,
      chkViewPersonaje,
      chkViewEval,
      chkViewMaterial,
      chkViewMoreInfo,
      chkViewVigencia,
      chkViewEquiposLit,
      chkDownloadFT,
      chkProductionGeneralView, //AHDA 03/06/2019
      chkSalesGeneralView, //AHDA 03/06/2019
      productor
    } = this.props;



    let arrThumbs = new Array();
    // isanchez
    if (detailContent) {
      if (detailContent.multimedia) {
        detailContent.multimedia.map(item => {
          if (item.arr) {
            item.arr.map(t => {
              if (t["394x275"]) {
                arrThumbs.push(t["394x275"])
              }
            })
          }
        })
      }
    }

    const { value } = this.state;

    const settings = {
      infinite: false,
      dots: true,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const { dense, secondary } = this.state;

    //AHDA 02/05/2019 
    //Added render for scripts  
    const renderScripts = () => {

      if (detailWork.idUserBlocked) {
        if (detailWork.idUserBlocked.split(",").includes(returnCurrentUserId())) {
          return (
            <TableElegirLibretto
              info={{ titulo_original: detailWork.titulo_original }}
              data={this.state.scripts}
              handleViewFile={this.handleViewFile}
              clickSelect={this.handleClickSelectFiles}
              tableButtonText={"Descargar"}
            >
            </TableElegirLibretto>
          )
        }
      }

      return detailWork.libretos.map((item) => {
        if (item.libretos.length > 0)
          return (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.flexItem}>
                  <List dense={dense} component="nav" className={classes.removePadding}>
                    <ListItem className={classes.removePadding}>
                      {/*CH 04/06/2019: Se acomoda texto secundario*/}
                      <Hidden xsDown>
                        <ListItemAvatar>
                          <Avatar>
                            <LibraryBooks />
                          </Avatar>
                        </ListItemAvatar>
                      </Hidden>
                      {/*AHDA 06/06/2019: Added validation for missing_chapaters*/}
                      <ListItemText
                        primary={`Libretos ${item.descripcion}`}
                        secondary={`(1 al ${item.total_chapters}) ${chkViewScripts ? `Faltan: ${item.missing_chapters ? item.missing_chapters : "-"}` : !detailWork.idUserBlocked ? "" : detailWork.idUserBlocked.split(",").includes(returnCurrentUserId()) ? `Faltan: ${item.missing_chapters}` : ""
                          } `}
                      />
                    </ListItem>
                  </List>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    {/*AHDA 25/04/2019: Added validation for check material*/}
                    <DetailTable
                      data={item.libretos}
                      clickView={this.handleClickView}
                      handleDownload={this.handleDownload}
                      canView={chkViewScripts}>
                    </DetailTable>

                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        else
          return null;
      })
    }

    const getImg = (detailWork) => {
      if (arrThumbs.length > 0) {
        return <Slider {...settings}>
          {
            this.state.auxMultimedia.length > 0 ?
              this.state.auxMultimedia.map((item, index) => {
                return (
                  <div key={index.toString()} className={classes.item}>
                    {
                      item.type === "img" ?
                        //isanchez 3.05.2019 
                        <img src={this.state[`photo_${index}`] ? this.state[`photo_${index}`] : arrThumbs[index]} onError={(event) => this.addDefaultSrc(event, item.url, index)} /> :
                        <div className={classes.itemVideo} onClick={() => this.handleOpenModal(item)}>
                          <VideoThumbnail
                            videoUrl={item.keyUrl}
                          />
                          <img src={iconPlay} className={classes.absolute} />
                        </div>
                      // <div className={classes.itemVideo}>  
                      //   <img src={iconPlay} alt="" onClick={() => this.handleOpenModal(item)} />  
                      // </div>  
                    }
                  </div>
                );
              }) :

              detailWork.formato === 'Radionovela' ?
                <div className={classes.item}>
                  <img src={radionovelaImage} alt="" />
                </div>
                :
                <div className={classes.item}>
                  <img src={defaultImage} alt="" />
                </div>

          }
        </Slider>
      } else {
        return <Slider {...settings}>
          {
            this.state.auxMultimedia.length > 0 ?
              this.state.auxMultimedia.map((item, index) => {
                return (
                  <div key={index.toString()} className={classes.item}>
                    {
                      item.type === "img" ?
                        // CH 25/07/2019
                        // Se elimina medida fija.
                        <S3Image
                          // theme={{ photoImg: { width: '394px', height: '260px', objectFit: 'cover' } }} 
                          imgKey={item.url} /> :
                        <div className={classes.itemVideo} onClick={() => this.handleOpenModal(item)}>
                          <VideoThumbnail
                            videoUrl={item.keyUrl}
                          />
                          <img src={iconPlay} className={classes.absolute} />
                        </div>
                    }
                  </div>
                );
              }) :
              detailWork.formato === 'Radionovela' ?
                <div className={classes.item}>
                  <img src={radionovelaImage} alt="" />
                </div>
                :
                <div className={classes.item}>
                  <img src={defaultImage} alt="" />
                </div>
          }
        </Slider>
      }
    }




    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>

              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="title" color="inherit" className={classes.flex}>
                    {/*AHDA 22/04/2019: Added other languages*/}
                    {detailWork.titulo_original}
                    {detailWork["titulo_inglés"] ? ` / ${detailWork["titulo_inglés"]}` : ""}
                    {detailWork["titulo_otro_idioma"] ? ` / ${detailWork["titulo_otro_idioma"]}` : ""}
                  </Typography>
                  <IconButton color="inherit" onClick={() => close()} aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              {this.state.uploadProgress && <LoaderFull process={this.state.uploadProgress} />}
              {
                loading ?
                  <div>
                    <Loader text="Cargando..." />
                  </div> :
                  <div className={classes.detailContainer}>
                    <Grid container className={classes.root} spacing={24}>
                      <Grid item md={12} sm={12} xs={12} className={classes.exportButtonContainer}>
                        {chkDownloadFT &&
                          <AtomButton color={'primary'} rounded onClick={this.handleDownloadFT}>{lang.playDetail.downloadResume}</AtomButton>
                        }
                        {(markAsLockEnable(detailWork.id_obras) && returnCurrentUser().id_rol === 33) &&
                          <AtomButton color={'primary'} rounded onClick={this.unlockWork}>{lang.playDetail.unlockWork}</AtomButton>
                        }
                      </Grid>
                      <Grid item md={5} sm={12} xs={12}>
                        <div className="container detalleSlider">
                          {getImg(detailWork)}
                        </div>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <div id="sinopsis_corta" hidden dangerouslySetInnerHTML={{ __html: (this.convertContent(detailWork.sinopsis_corta)) }}></div>
                        <div id="sinopsis_larga" hidden dangerouslySetInnerHTML={{ __html: (this.convertContent(detailWork.sinopsis_larga)) }}></div>
                        <section className={classes.detailWrap} >
                          <Typography noWrap gutterBottom variant="headline" className={classes.title} component="h2">
                            {detailWork.titulo_original}
                          </Typography>
                          <div className={classes.price}>
                            {(detailWork.vigencias && !productor) && <Chip label={detailWork.vigencias.type} className={getChipClassNames(classes, detailWork.vigencias.type)} />}

                            <Chip label={detailWork.tipo_obra === "primigenia" ? "Primigenia" : "Adaptación"} className={getChipClassNames(classes, detailWork.tipo_obra)} />

                            {detailWork.produccion && <Chip label={detailWork.produccion} className={getChipClassNames(classes, detailWork.produccion)} />}

                            <Language /> <span>{detailWork.pais}</span>
                          </div>
                          <Typography component="div" className="detailObra">
                            {
                              detailWork.tipo_obra === "adaptacion" &&
                              <div className="pb2">
                                {/* isanchez 09.05.2019 add attribute for obra_primigenia */}
                                {/* JCMY 20/06/2019 */}
                                {/* isanchez 28.06.2019 add validation for only show detail if the attribute valid => 1 */}
                                <strong>{lang.playDetail.originalWorkTitle}</strong> <span className={detailWork.validPrimigenia && detailWork.validPrimigenia === 1 ? classes.cursorPointer : ''} onClick={() => {
                                  if (detailWork.validPrimigenia && detailWork.validPrimigenia === 1) {
                                    this.props.handleDetailOpen({ id_obras: detailWork.id_obra_primigenia });
                                  }
                                }}>{detailWork.obra_primigenia ? detailWork.obra_primigenia : ''}</span>
                              </div>
                            }
                            {/* isachez 13.08.2019 show more than one primigeneishon   */}
                            {
                              detailWork.tipo_obra === "adaptacion" ?
                                <div className="pb2">
                                  <strong className="dBlock">Otras Obras Primigenias:</strong>
                                  {
                                    detailWork.otherPrimigenia ? detailWork.otherPrimigenia.map((item, index) => {
                                      return (
                                        <span className="dBlock">{item.titulo_original}</span>
                                      );
                                    }) : ""
                                  }
                                </div> : ""
                            }
                            {/*AHDA 30/05/2019: Changed validation and mapping*/}
                            {
                              detailWork.tipo_obra === "adaptacion" || detailWork.producida === 1 ?
                                <div className="pb2">
                                  <strong className="dBlock">{lang.playDetail.otherVersions}:</strong>
                                  {
                                    detailWork.version_titles ? detailWork.version_titles.map((item, index) => {
                                      return (
                                        <span className="dBlock">{item.titulo_original}</span>
                                      );
                                    }) : ""
                                  }
                                </div> : ""
                            }


                            <div className="pb2">
                              {
                                detailWork.producida === 1 &&
                                <React.Fragment><span><strong>{lang.playDetail.proudctionYear}:</strong> <i>{detailWork.anio_produccion}</i></span></React.Fragment>
                              }
                              {/* isanchez 09.05.2019 hide min */}
                              {/* AHDA 14/05/2019: Mapped duracion_capMin*/}
                              {/* isanchez 14.05.2019 hide min */}
                              {/* AHDA 24/05/2019: added min */}
                              <span><strong>{lang.playDetail.chapters}:</strong> <i>{detailWork.numero_capitulos} x {detailWork.duracion_capMin ? `${detailWork.duracion_capMin} min.` : '-'}</i></span>
                              {
                                detailWork.producida === 1 &&
                                <React.Fragment><span><strong>{lang.playDetail.airedChapters}:</strong> <i>{detailWork.ep_alAire} x {detailWork.duracion_capProd ? `${detailWork.duracion_capProd} min.` : "-"}</i></span></React.Fragment>
                              }
                            </div>
                            <div className="pb2">
                              <span><strong>{lang.playDetail.authors}:</strong> <i>{detailWork.autores}</i></span>
                            </div>
                            {
                              detailWork.tipo_obra === "adaptacion" &&
                              <div className="pb2">
                                <span><strong>{lang.playDetail.adapters}:</strong> <i>{detailWork.adaptadores}</i></span>
                              </div>
                            }
                            {
                              detailWork.producida === 1 &&
                              <div className="pb2">
                                <span><strong>{this.getCatalogName(16)}:</strong> <i>{detailWork.productores}</i></span>
                              </div>
                            }
                            <div className="pb2">
                              <span><strong>{this.getCatalogName(8)}:</strong> <i>{detailWork.formato}</i></span>
                              <span><strong>{this.getCatalogName(9)}:</strong> <i>{detailWork.ventana}</i></span>
                            </div>
                            <div className="pb2">
                              <span><strong>{this.getCatalogName(10)}:</strong> <i>{detailWork.audiencia}</i></span>
                              {/* JCMY 05/07/2019 incidecnia 695*/}
                              <span><strong>{lang.playDetail.schedule}:</strong> <i>{detailWork.horario != null ? detailWork.horario + " hrs" : ""} </i></span>
                              <span><strong>{this.getCatalogName(22)}:</strong> <i>{detailWork.horariosugerido && detailWork.horariosugerido.map(e => e.descripcion).join(' ,')}</i></span>
                            </div>
                          </Typography>
                          <Divider className={classes.divider} />
                          <Typography component="p" className="detailExtra">
                            <span className="dBlock">
                              <span><strong>{this.getCatalogName(5)}:</strong> <i>{detailWork.genero}</i></span>
                              <span><strong>{this.getCatalogName(6)}:</strong> <i>{detailWork.subgenero}</i></span>
                              <span><strong>{this.getCatalogName(7)}:</strong> <i>{detailWork.tematica}</i></span>
                            </span>
                            <span className="dBlock">
                              <span><strong>{this.getCatalogName(11)}:</strong> <i>{detailWork.motivacion}</i></span>
                              <span><strong>{this.getCatalogName(12)}:</strong> <i>{detailWork.temporalidad}</i></span>
                              <span><strong>{this.getCatalogName(13)}:</strong> <i>{detailWork.ambiente}</i></span>
                            </span>
                          </Typography>
                          <Divider className={classes.divider} />
                          <Typography component="p" className="detailExtra">
                            <span className="dBlock">
                              {detailWork.nivelSocioeconomico && <span><strong>{this.getCatalogName(26)}:</strong> <i>{detailWork.nivelSocioeconomico}</i></span>}
                              {detailWork.numLineas && <span><strong>{this.getCatalogName(27)}:</strong> <i>{detailWork.numLineas}</i></span>}
                              {detailWork.manejoTiempo && <span><strong>{this.getCatalogName(28)}:</strong> <i>{detailWork.manejoTiempo}</i></span>}
                            </span>
                            <span className="dBlock">
                              {detailWork.tipoRomance && <span><strong>{this.getCatalogName(29)}:</strong> <i>{detailWork.tipoRomance}</i></span>}
                              {detailWork.trayectoriaPortagonista && <span><strong>{this.getCatalogName(30)}:</strong> <i>{detailWork.trayectoriaPortagonista}</i></span>}
                            </span>
                          </Typography>
                          {(detailWork.nivelSocioeconomico ||detailWork.numLineas || detailWork.manejoTiempo || detailWork.tipoRomance || detailWork.trayectoriaPortagonista) && <Divider className={classes.divider} />}
                          <Typography component="p" className="detailDescription">
                            <div className={classes.justify} >{detailWork.logline}  </div>
                          </Typography>
                        </section>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <AppBar position="static" color="primary" className={classes.gradientBackground}>
                          <Tabs
                            value={value}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            scrollable
                            scrollButtons="on"
                            className={classes.tabsContainer}
                          >
                            {chkViewSinopsis && (<Tab value="synopsis" label={lang.playDetail.synopsis} />)}
                            {chkViewPersonaje && (<Tab value="characters" label={lang.playDetail.characters} />)}
                            {chkViewEval && (<Tab value="evaluation" label={lang.playDetail.evaluation} />)}
                            {chkViewMaterial && (<Tab value="material" label={lang.playDetail.material} />)}
                            {chkViewMoreInfo && (<Tab value="moreInfo" label={lang.playDetail.moreInfo} />)}
                            {chkViewVigencia && (<Tab value="six" label={lang.playDetail.validity} />)}
                            {chkViewEquiposLit && (<Tab value="seven" label={lang.playDetail.literaryTeams} />)}
                          </Tabs>
                        </AppBar>

                        {/* TAP SINOPSIS */}
                        {value === 'synopsis' &&
                          <TabContainer>
                            <div>
                              <Grid container spacing={24}>
                                <Grid item xs={12}>
                                  <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{lang.playDetail.shortSynopsis}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.heightMax}>
                                      {/* <Typography>   */}
                                      <div className={classNames(classes.justify, classes.pNoMargin)} dangerouslySetInnerHTML={{ __html: (this.convertContent(detailWork.sinopsis_corta)) }}></div>
                                      {/* {ParseLogLine(detailWork.sinopsis_corta)} */}
                                      {/* </Typography>   */}
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{lang.playDetail.longSynopsis}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.heightMax}>
                                      {/* CH 14/08/19 Se unifican textos de sinopsis corta y larga  */}
                                      <div className={classNames(classes.justify, classes.pNoMargin)} dangerouslySetInnerHTML={{ __html: (this.convertContent(detailWork.sinopsis_larga)) }}></div>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                </Grid>
                              </Grid>
                            </div>
                          </TabContainer>
                        }

                        {
                          value === 'characters' &&
                          <TabContainer>
                            <div>
                              <Grid container spacing={24}>
                                <Grid item xs={12}>
                                  <Grid item container direction="row" justify="flex-end" alignItems="flex-start" className="pb3">
                                    <Button variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.handleClickView(detailWork.diagrama_relaciones)}>
                                      {lang.playDetail.relationshipDiagram}
                                      <Icon className={classes.rightIcon}>picture_as_pdf</Icon>
                                    </Button>
                                  </Grid>
                                  <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={24}>
                                    {detailWork.personajes.map((item, index) => {
                                      return (
                                        <Grid item xs={12} md={6} >
                                          <Paper className={classes.paper}>
                                            <div className={classes.contentFlex}>

                                              <div>
                                                <Typography component="p" className={classes.labelTitle}>
                                                  {lang.playDetail.name}:
                                                </Typography>
                                                <b>{item.nombre}</b>
                                              </div>
                                              {/* isanchez 09.05.2019 hide AÑOS */}
                                              <div>
                                                <Typography component="p" className={classes.labelTitle}>
                                                  {lang.playDetail.age}:
                                                </Typography>
                                                {item.edad} {/*AÑOS*/}
                                              </div>
                                            </div>

                                            <Divider className={classes.divider} />

                                            <div className="espaciadoP">
                                              <Typography component="p" className={classNames(classes.labelTitle, classes.pt2)}>
                                                {lang.playDetail.characterPsychology}:
                                              </Typography>
                                              <Typography component="p" className="textJustify">
                                                {item.psicologia}
                                              </Typography>
                                            </div>
                                          </Paper>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </TabContainer>
                        }

                        {/* TAP EVALUACIÓN */}
                        {
                          value === 'evaluation' &&
                          <TabContainer>
                            <TabEvaluacion data={detailWork.evaluaciones} />
                          </TabContainer>
                        }

                        {/* TAP MATERIAL */}
                        {
                          value === 'material' &&
                          <TabContainer>
                            <div>
                              <Grid
                                container
                                className={classes.alignButton}
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                spacing={24}>
                                <Grid item xs={12}>
                                  <div>
                                    {/*AHDA 29/04/2019: added onClick, disabled*/}
                                    <Button disabled={!canApplyForMaterial} variant="contained" size="small" color="primary" className={classes.button} onClick={this.handleRequestScripts}>
                                      {lang.playDetail.requestScripts}
                                      <Icon className={classes.rightIcon}>chevron_right</Icon>
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>

                              {/*---------------------SCRIPTS---------------------*/}
                              {
                                detailWork.libretos.length > 0 ?
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{lang.playDetail.scripts}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid container spacing={24}>
                                        <Grid item xs={12}>

                                          {/*AHDA 02/05/2019: Added rendering depending on projects*/}
                                          {
                                            renderScripts()
                                          }


                                        </Grid>
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel> : ''
                              }

                              {/*---------------------VIDEOS---------------------*/}
                              {
                                detailWork.videos.length > 0 ?
                                  <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{lang.playDetail.videos}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <MaterialVideos
                                        dataProduct={detailWork.videos}
                                        handleOpenModal={this.handleOpenModal}
                                        catalogName={this.getCatalogName(13)}
                                        handleDownload={this.handleDownload}
                                      />
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel> : ''
                              }

                              {/*---------------------IMÁGENES---------------------*/}
                              {
                                detailWork.imagenes.length > 0 ?
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{lang.playDetail.images}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <ImageCarousel imgData={detailWork.imagenes}></ImageCarousel>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel> : ''
                              }

                              {/*---------------------OTHER---------------------*/}
                              {
                                detailWork.biblia.length > 0 || detailWork.brochure.length > 0 || detailWork.escaleta.length || detailWork.dialogo.length > 0 ?
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{lang.playDetail.others}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                          {
                                            detailWork.biblia.length > 0 ?
                                              <ExpansionPanel >
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                  <div className={classes.flexItem}>
                                                    <List dense={dense} component="nav" className={classes.removePadding}>
                                                      <ListItem>
                                                        <ListItemAvatar>
                                                          <Avatar>
                                                            <Book />
                                                          </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                          primary={`${lang.playDetail.bible}`}
                                                          secondary={secondary ? 'Secondary text' : null}
                                                        />
                                                      </ListItem>
                                                    </List>
                                                    <Typography className={classNames(classes.secondaryHeading, classes.colorSecondaryText)}>
                                                      ({detailWork.biblia.length} idiomas)
                                                    </Typography>
                                                  </div>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    spacing={16}>
                                                    <Grid item xs={12} sm={6} md={12}>
                                                      <List dense={dense} component="nav" >
                                                        {detailWork.biblia.map((item, index) => (
                                                          (<ListItem>
                                                            <ListItemAvatar>
                                                              <Avatar>
                                                                <Language />
                                                              </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item.descripcion} secondary={secondary ? 'Secondary text' : null} />
                                                            <ListItemSecondaryAction>
                                                              <Tooltip title="Ver">
                                                                <IconButton aria-label="Visibility" onClick={() => this.handleClickView(item.ruta)}>
                                                                  <Visibility />
                                                                </IconButton>
                                                              </Tooltip>
                                                              <IconButton onClick={() => this.handleDownload(item.ruta)}>
                                                                <CloudDownload />
                                                              </IconButton>
                                                            </ListItemSecondaryAction>
                                                            <ListItemText
                                                              primary={`Descripción: ${item.description || ''}`}
                                                              secondary={`Cantidad: ${item.amount || ''}`}
                                                            />
                                                          </ListItem>)
                                                        ))}
                                                      </List>
                                                    </Grid>
                                                  </Grid>
                                                </ExpansionPanelDetails>
                                              </ExpansionPanel> : ''
                                          }

                                          {
                                            detailWork.brochure.length > 0 ?
                                              <ExpansionPanel >
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                  <div className={classes.flexItem}>
                                                    <List dense={dense} component="nav" className={classes.removePadding}>
                                                      <ListItem>
                                                        <ListItemAvatar>
                                                          <Avatar>
                                                            <Book />
                                                          </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                          primary={`${lang.playDetail.brochure}`}
                                                          secondary={secondary ? 'Secondary text' : null}
                                                        />
                                                      </ListItem>
                                                    </List>
                                                    <Typography className={classNames(classes.secondaryHeading, classes.colorSecondaryText)}>
                                                      ({detailWork.brochure.length} idiomas)
                                                    </Typography>
                                                  </div>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    spacing={16}>
                                                    <Grid item xs={12} sm={6} md={12}>
                                                      <List dense={dense} component="nav" >
                                                        {detailWork.brochure.map((item, index) => (
                                                          (<ListItem>
                                                            <ListItemAvatar>
                                                              <Avatar>
                                                                <Language />
                                                              </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item.descripcion} secondary={secondary ? 'Secondary text' : null} />
                                                            <ListItemSecondaryAction>
                                                              <IconButton aria-label="Visibility" onClick={() => this.handleClickView(item.ruta)}>
                                                                <Visibility />
                                                              </IconButton>
                                                              <IconButton onClick={() => this.handleDownload(item.ruta)}>
                                                                <CloudDownload />
                                                              </IconButton>
                                                            </ListItemSecondaryAction>
                                                            <ListItemText
                                                              primary={`Descripción: ${item.description || ''}`}
                                                              secondary={`Cantidad: ${item.amount || ''}`}
                                                            />
                                                          </ListItem>)

                                                        ))}

                                                      </List>
                                                    </Grid>
                                                  </Grid>
                                                </ExpansionPanelDetails>
                                              </ExpansionPanel> : ''
                                          }

                                          {
                                            detailWork.escaleta.length > 0 ?
                                              <ExpansionPanel >
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                  <div className={classes.flexItem}>
                                                    <List dense={dense} component="nav" className={classes.removePadding}>
                                                      <ListItem>
                                                        <ListItemAvatar>
                                                          <Avatar>
                                                            <Book />
                                                          </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                          primary={`${lang.playDetail.escaleta}`}
                                                          secondary={secondary ? 'Secondary text' : null}
                                                        />
                                                      </ListItem>
                                                    </List>
                                                    <Typography className={classNames(classes.secondaryHeading, classes.colorSecondaryText)}>
                                                      ({detailWork.escaleta.length} idiomas)
                                                    </Typography>
                                                  </div>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    spacing={16}>
                                                    <Grid item xs={12} sm={6} md={12}>
                                                      <List dense={dense} component="nav" >
                                                        {detailWork.escaleta.map((item, index) => (
                                                          <ListItem>
                                                            <ListItemAvatar>
                                                              <Avatar>
                                                                <Language />
                                                              </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                              primary={item.descripcion}
                                                              secondary={secondary ? 'Secondary text' : null}
                                                            />
                                                            <ListItemSecondaryAction>
                                                              <IconButton aria-label="Visibility" onClick={() => this.handleClickView(item.ruta)}>
                                                                <Visibility />
                                                              </IconButton>
                                                              <IconButton onClick={() => this.handleDownload(item.ruta)}>
                                                                <CloudDownload />
                                                              </IconButton>
                                                            </ListItemSecondaryAction>
                                                            <ListItemText
                                                              primary={`Descripción: ${item.description || ''}`}
                                                              secondary={`Cantidad: ${item.amount || ''}`}
                                                            />
                                                          </ListItem>

                                                        ))}
                                                      </List>
                                                    </Grid>
                                                  </Grid>
                                                </ExpansionPanelDetails>
                                              </ExpansionPanel> : ''
                                          }
                                          {
                                            detailWork.dialogo.length > 0 ?
                                              <ExpansionPanel >
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                  <div className={classes.flexItem}>
                                                    <List dense={dense} component="nav" className={classes.removePadding}>
                                                      <ListItem>
                                                        <ListItemAvatar>
                                                          <Avatar>
                                                            <Book />
                                                          </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                          primary={`Diálogo`}
                                                          secondary={secondary ? 'Secondary text' : null}
                                                        />
                                                      </ListItem>
                                                    </List>
                                                    <Typography className={classNames(classes.secondaryHeading, classes.colorSecondaryText)}>
                                                      ({detailWork.escaleta.length} idiomas)
                                                    </Typography>
                                                  </div>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    spacing={16}>
                                                    <Grid item xs={12} sm={6} md={12}>
                                                      <List dense={dense} component="nav" >
                                                        {detailWork.dialogo.map((item) => (
                                                          <ListItem>
                                                            <ListItemAvatar>
                                                              <Avatar>
                                                                <Language />
                                                              </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                              primary={item.descripcion}
                                                              secondary={secondary ? 'Secondary text' : null}
                                                            />
                                                            <ListItemSecondaryAction>
                                                              <IconButton aria-label="Visibility" onClick={() => this.handleClickView(item.ruta)}>
                                                                <Visibility />
                                                              </IconButton>
                                                              <IconButton onClick={() => this.handleDownload(item.ruta)}>
                                                                <CloudDownload />
                                                              </IconButton>
                                                            </ListItemSecondaryAction>
                                                            <ListItemText
                                                              primary={`Descripción: ${item.description || ''}`}
                                                              secondary={`Cantidad: ${item.amount || ''}`}
                                                            />
                                                          </ListItem>

                                                        ))}
                                                      </List>
                                                    </Grid>
                                                  </Grid>
                                                </ExpansionPanelDetails>
                                              </ExpansionPanel> : ''
                                          }
                                        </Grid>
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel> : ''
                              }
                            </div>
                          </TabContainer>
                        }
                        {
                          value === 'moreInfo' &&
                          <TabContainer>
                            <TabMoreInfo data={this.state.auxMoreInfo} />
                          </TabContainer>
                        }
                        {value === 'six' &&
                          <TabContainer>
                            <TabVigencias data={detailWork.vigencias || {}} titleTerritories={this.getCatalogName(23)} />
                          </TabContainer>}
                        {value === 'seven' && <TabContainer>
                          <TabEquiposLiterarios data={detailWork.equiposLit} />
                        </TabContainer>}
                      </Grid>
                    </Grid>


                    <RelatedWorks
                      dataProduct={detailWork.versiones}
                      screen={this.props.screen}
                      handleNotifications={() => this.handleNotifications()}
                      handleDetailOpen={this.props.handleDetailOpen}
                      type={detailWork.version_titles.length == 0 ? lang.playDetail.relatdWorks : lang.playDetail.otherVersions}
                      canFavorite={canFavorite}
                      canAddProspect={canAddProspect}
                      canApplyForMaterial={canApplyForMaterial}
                      canAssignProyect={canAssignProyect}
                      canEndWork={canEndWork}
                      canShareMaterial={canShareMaterial}
                      canUnlockWork={canUnlockWork}
                      canViewWorkBlock={canViewWorkBlock}

                      chkProductionGeneralView={chkProductionGeneralView}
                      chkSalesGeneralView={chkSalesGeneralView}
                      productor={productor}
                    />
                    {/* }   */}
                  </div>
              }

              {/*Modal for multimedia*/}
              <Modal
                aria-labelledby="media-modal"
                aria-describedby=""
                open={this.state.openModal}
                onClose={this.handleCloseModal}
                className="modalArrows"
              >
                <MediaModalSlider
                  openModal={this.state.openModal}
                  handleCloseModal={this.handleCloseModal}
                  media={[this.state.selectedVideo]} />
              </Modal>

              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                <CustomNotification onClose={this.handleClose} variant={this.state.variant} message={this.state.message} />
              </Snackbar>
            </Dialog>
        }
      </LanguageConsumer>
    );
  }
}


ProductDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  detailContent: PropTypes.object,
  productIndex: PropTypes.number,
  detailWork: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  canFavorite: PropTypes.bool,
  canAddProspect: PropTypes.bool,
  canApplyForMaterial: PropTypes.bool,
  canAssignProyect: PropTypes.bool,
  canEndWork: PropTypes.bool,
  canShareMaterial: PropTypes.bool,
  canUnlockWork: PropTypes.bool,
  canViewWorkBlock: PropTypes.bool,
  chkViewScripts: PropTypes.bool,
  screen: PropTypes.string //AHDA 03/06/2019
};

ProductDetail.defaultProps = {
  productIndex: undefined,
  loading: false,
  chkViewScripts: false,
  chkViewSinopsis: false,
  chkViewPersonaje: false,
  chkViewEval: false,
  chkViewMaterial: false,
  chkViewMoreInfo: false,
  chkViewVigencia: false,
  chkViewEquiposLit: false,
  chkDownloadFT: false,
  screen: "" //AHDA 03/06/2019
};

ProductDetail.contextType = LanguageCxt;

export default withStyles(styles)(ProductDetail);
