import { Button, Divider, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, withStyles, Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import styles from './nuevaObra-jss'
import classNames from 'classnames'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { ButtonAction } from '../../atoms';
import { Add, Delete } from '@material-ui/icons';
import { LanguageCxt } from '../../language/LanguageContext';
import moment from 'moment';

const StepMoreInfo = ({ 
    classes,
    canViewMoreInfo,
    producerUsers,
    assignProspects,
    assignProject,
    data,
    updateValues,
    markAsProspect,
    canMarkProspect,
    canAssignProject,
    canUnlockWork,
    canEndWork
}) => {
    const { lang } = useContext(LanguageCxt);
    const [prospects, setProspects] = useState(data.assignedProspects || []);
    const [project, setProject] = useState(data.assignedTo ? true : false)
    const [localProspects, setLocalProspects] = useState(data.assignedProspects || [])
    const [changeData, setChangeData] = useState(false)
    let originProspects = data.assignedProspects

    useEffect(() =>{
        if(changeData === true) {
            setLocalProspects(prospects)
            setChangeData(false)
        }  
    }, [prospects, changeData === true])

    const handleAddRemove = (key) => {
        const temp = prospects.map((e) => ({
            ...e,
            assignedDate: e.assignedDate !== undefined && e.assignedDate !== null 
                            ? e.assignedDate 
                            : moment().format('YYYY-MM-DD')
        }));
        if (key !== undefined) {
            temp[key].isAdded = false;
            if (temp[key].idProspectos) {
                assignProspects([temp[key]])
            }
            temp.splice(key, 1)
            originProspects.splice(key, 1)
            if(temp.length === 0){
                data.markedProspect = false
                handleMarkProspect({target: {checked: false}})
            }
        } else {
            temp.push({ assignedUser: "", assignedDate: moment().format('YYYY-MM-DD'), assignedComments: "", isAdded: true })
        }
        setProspects([...temp])
        setLocalProspects([...originProspects])
    }

    const handleChange = (input, value) => {
        const temp = data;
        temp[input] = value;
        temp[`${input}_err`] = false;
        updateValues(temp);
    }

    const handleChangeList = (input, event, key) => {
        let temp = prospects;
        temp[key][`${input}_err`] = false;
        if (input === "assignedDate") {
            const f = new Date(event).toISOString();
            temp[key][input] = f.split("T")[0];
        } else {
            temp[key][input] = event.target.value;
        }
        setProspects([...temp]);
    }

    const handleMarkProspect = (event) => {
        handleChange("markedProspect", event.target.checked)
        markAsProspect(event.target.checked)
        if(prospects.length === 0 && event.target.checked)
            setProspects([
                ...prospects,
                { assignedUser: "", assignedDate: moment().format('YYYY-MM-DD'), assignedComments: "", isAdded: true }
            ])
        if (event.target.checked === false) {
            // if(data.assignedTo){
            //    assignProject(0); 
            // }
            if (prospects.length > 0) {
                const temp = prospects;
                temp.forEach(item => {
                    item.isAdded = false;
                    if(!item.assignedDate)
                        item.assignedDate = moment().format('YYYY-MM-DD')
                });
                assignProspects(temp)
                setProspects([])
            }
        }
    }

    const submitProspects = () => {
        const areObjectsEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            return keys1.every(key => obj1[key] === obj2[key]);
        };

        const tempArr = localProspects
            .filter(item => !prospects.some(other => areObjectsEqual(item, other)))
            .concat(prospects.filter(item => !localProspects.some(other => areObjectsEqual(item, other))));


        assignProspects(tempArr);
        setChangeData(true)
    }

    const assigned = prospects.map(e => parseInt(e.assignedUser));

    const [expandedPanel, setExpandedPanel] = useState(null);

    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : null);
    };

    return <section className={classes.bodyForm}>
        <Card>
            <CardContent><Typography className={classes.stepHeadingExpand}>{lang.tabMoreInfo.title}</Typography></CardContent>
            <ExpansionPanel expanded={expandedPanel === 'panel1'} onChange={handlePanelChange('panel1')}>
                <ExpansionPanelSummary>
                <Checkbox
                    className={classes.stepHeadingCheckbox}
                    id='markedProspect'
                    name='markedProspect'
                    checked={data.markedProspect}
                    onChange={handleMarkProspect}
                    disabled={!(canViewMoreInfo && canMarkProspect)}
                />
                <Typography variant='body1' component="span" className={classes.checkboxOption, classes.stepHeadingExpand}>{lang.tabMoreInfo.markAsProspect}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={16} className={classes.contentContainer}>
                        <Grid item xs={12}>{prospects.length > 0 ?
                            <></>
                            :
                            <Typography variant='body1' component="span" className={classes.checkboxOption}>{lang.tabMoreInfo.assignUser}</Typography>}
                        </Grid>
                        {prospects.map((item, key) =>
                            <>
                                <Grid item xs={12} sm={6}>
                                <div className={classes.flexCell}>
                                    <FormControl className={classNames(classes.field, classes.w100)}>
                                        <InputLabel shrink htmlFor="new_author_name">{lang.tabMoreInfo.assignedUser}*</InputLabel>
                                        <Select
                                            value={item.assignedUser}
                                            onChange={(e) => handleChangeList("assignedUser", e, key)}
                                            disabled={!canViewMoreInfo || !data.markedProspect || !canMarkProspect}
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            InputLabelProps={{ shrink: true }}
                                            error={item.assignedUser_err}
                                        >
                                            {
                                                producerUsers.map((e) => (
                                                    <MenuItem key={`producer_${e.value}`} disabled={assigned.includes(parseInt(e.value))} value={e.value}>{e.data}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        keyboard={false}
                                        label={`${lang.tabMoreInfo.assignedDate}*`}
                                        className={classes.w100}
                                        format="DD/MM/YYYY"
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        value={!item.assignedDate ? Date.now() : item.assignedDate}
                                        onChange={(e) => handleChangeList("assignedDate", e, key)}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        clearable={true}
                                        cancelLabel={lang.common.cancel}
                                        clearLabel={lang.common.clear}
                                        placeholder="DD/MM/AAAA"
                                        InputLabelProps={{ shrink: true }}
                                        disabled={!canViewMoreInfo || !data.markedProspect || !canMarkProspect}
                                        error={item.assignedDate_err}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className={classes.flexRowEnd}>
                                <TextField
                                    label={lang.tabMoreInfo.comments}
                                    placeholder={lang.tabMoreInfo.commentsPlaceholder}
                                    multiline
                                    rows="3"
                                    className={classNames(classes.field, classes.w100)}
                                    onChange={(e) => handleChangeList("assignedComments", e, key)}
                                    value={item.assignedComments}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!canViewMoreInfo || !data.markedProspect || !canMarkProspect}
                                />
                                <ButtonAction
                                    rounded
                                    color="default"
                                    disabled={!canViewMoreInfo || !data.markedProspect || !canMarkProspect}
                                    className={classes.buttonRemove}
                                    onClick={() => { handleAddRemove(key) }}>
                                    <Delete />
                                </ButtonAction>
                            </Grid>
                        </>
                    )}
                    {
                        <div className={classes.actionPaddingTop}>
                            <ButtonAction
                                rounded
                                color="default"
                                disabled={!canViewMoreInfo || !data.markedProspect || !canMarkProspect}
                                className={classes.buttonAdd}
                                onClick={() => handleAddRemove()}>
                                <Add />
                            </ButtonAction>
                            {
                                prospects.length > 0 &&
                                <Button variant="contained" color="primary" className={classes.minW130} onClick={submitProspects} disabled={!canViewMoreInfo || !data.markedProspect || !canMarkProspect || localProspects === prospects}>
                                    {lang.tabMoreInfo.assignWork}
                                </Button>
                            }

                        </div>
                    }
                </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expandedPanel === 'panel2'} onChange={handlePanelChange('panel2')}>
                <ExpansionPanelSummary>
                    <Checkbox
                        className={classes.stepHeadingCheckbox}
                        id='MarkProject'
                        name='MarkProject'
                        checked={project}
                        onChange={(event) => setProject(event.target.checked)}
                        disabled={!(canViewMoreInfo && canAssignProject)}
                    />
                    <Typography variant='body1' component="span" className={classes.checkboxOption, classes.stepHeadingExpand}>Marcar como proyecto</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={6}>
                                <div className={classNames(classes.flexRowEnd)}>
                                    <FormControl className={classNames(classes.field)}>
                                        <InputLabel shrink htmlFor="new_author_name">{lang.tabMoreInfo.assignedto}*</InputLabel>
                                        <Select
                                            name="assignedTo"
                                            value={data.assignedTo}
                                            onChange={(e) => handleChange("assignedTo", e.target.value)}
                                            disabled={!canViewMoreInfo || !project || data.idmisProyectos}
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            InputLabelProps={{ shrink: true }}
                                            error={data.assignedTo_err}
                                        >
                                            {
                                                producerUsers.map((e) => (
                                                    <MenuItem key={`producer_${e.value}`} value={e.value}>{e.data}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.flexRowEnd}>
                                {
                                    !data.idmisProyectos &&
                                    <Button variant="contained" color="primary" onClick={() => assignProject(1)} disabled={!(canViewMoreInfo && canAssignProject && project)}>
                                        {lang.tabMoreInfo.assignWork}
                                    </Button>
                                }
                                {
                                    data.idmisProyectos &&
                                    <Button variant="contained" color="primary" onClick={() => assignProject(0)} disabled={!(canViewMoreInfo && canUnlockWork && project)}>
                                        {lang.tabMoreInfo.unlockWork}
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    data.idmisProyectos &&
                                    <Button variant="outlined" color="primary" className={classes.minW130} onClick={() => assignProject(2)} disabled={!(canViewMoreInfo && canEndWork && project)}>
                                        {lang.tabMoreInfo.finishWork}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Card>
    </section>
}

export default withStyles(styles)(StepMoreInfo);