import ls from 'local-storage';

export const markAsFavorite = (id) => {
    id = Number.parseInt(id); //AHDA 31/05/2019
    const favorites = ls.get('favorites'); 
    if(favorites !== null){
        //AHDA 31/05/2019
        const auxIndex = favorites.filter(fav => fav.id_obras == id);
        return auxIndex.length > 0 ? true : false; 
    }else{
        return false; 
    }
}

export const updateFavorites = (id, added) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    let favorites = ls.get('favorites'); 
    if(favorites !== null){
        if(added){
            favorites.push({id_obras: id});
        }
        else{
            //AHDA 31/05/2019
            let auxIndex = favorites.findIndex(fav => fav.id_obras == id); 
            favorites.splice(auxIndex, 1); 
        }
        ls.set('favorites', favorites); 
    }
}

export const markAsProspect = (id) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    const prospects = ls.get('prospects'); 
    if(prospects !== null){
        //AHDA 31/05/2019
        const auxIndex = prospects.filter(pros => pros.id_obras == id)
        return auxIndex.length > 0 ? true : false; 
    }else{
        return false; 
    }
}

export const updateProspects = (id, added) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    let prospects = ls.get('prospects'); 
    if(prospects !== null){
        if(added){
            prospects.push({id_obras: id});
        }
        else{
            //AHDA 31/05/2019
            let auxIndex = prospects.findIndex(fav => fav.id_obras == id); 
            prospects.splice(auxIndex, 1); 
        }
        ls.set('prospects', prospects); 
    }
}

export const markAsProject = (id) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    const blocked = ls.get('blockedProjects'); 
    if(blocked !== null){
        //AHDA 31/05/2019
        const auxIndex = blocked.filter(proj => proj.id_obras == id);
        return auxIndex.length > 0 ? true : false; 
    }else{
        return false; 
    }
}

export const markAsMyProject = (id) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    const blocked = ls.get('blocked'); 
    if(blocked !== null){
        //AHDA 31/05/2019
        const auxIndex = blocked.myprojects.filter(proj => proj.id_obras == id)
        return auxIndex.length > 0 ? true : false; 
    }else{
        return false; 
    }
}

export const markAsBlocked = (id) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    
    const blocked = ls.get("blocked"); 
    if(blocked !== null){
        //AHDA 31/05/2019
        const auxProjects = blocked.projects.filter(proj => proj.id_obras == id);
        const auxProspects = blocked.prospects.filter(proj => proj.id_obras == id);

        if(auxProjects.length > 0){
            return {isBlocked: true, reason: 'project'}
        }
        else if(auxProspects.length > 0){
            return {isBlocked: true, reason: 'prospect'}
        }
        else
            return {isBlocked: false, reason: ''}
    }else{
        return {isBlocked: false, reason: ''} 
    }
}

export const markAsLockEnable = (id) => {
    id = Number.parseInt(id);

    const blocked = ls.get("prospects"); 
    if(blocked !== null){
        const auxProspects = blocked.filter(proj => proj.id_obras == id);
       
        if(auxProspects.length > 0){
            return true; 
        }
        else
            return false; 
    }else{
        return false; 
    }
}

export const markAsProjectEnable = (id) => {
    id = Number.parseInt(id);
    const blocked = ls.get("blocked");
    if (blocked !== null) {
        const auxProj = blocked.projects.find(proj => proj.id_obras === id);
        const auxProspects = blocked.prospects.find(proj => proj.id_obras === id);

        if (auxProj) {
            const myProjects = blocked.myprojects.find(proj => proj.id_obras === auxProj.id_obras);
            return !!myProjects;
        } 

        if (auxProspects) {
            const myProspects = blocked.myprospects.find(proj => proj.id_obras === auxProspects.id_obras);
            return !!myProspects;
        }

        return true;
    } else {
        return false;
    }
}


export const markAsExternalProspect = (id) => {
    id = Number.parseInt(id);  //AHDA 31/05/2019
    const blocked = ls.get("blocked"); 
    if(blocked !== null){
        //AHDA 31/05/2019
        const auxProspects = blocked.prospects.filter(proj => proj.id_obras == id);
       
        if(auxProspects.length > 0){
            return true; 
        }
        else
            return false; 
    }else{
        return false; 
    }
}

//AHDA 19/06/2019: Added method 
export const updateProjects = (id, added, external) => {
    id = Number.parseInt(id);
    if(external){
        let blocked = ls.get('blockedProjects'); 
        let divBlocked = ls.get('blocked'); 
        
        if(blocked !== null && divBlocked !== null){
            if(added){
                blocked.push({id_obras: id, reason: "project"}); 
                divBlocked.projects.push({id_obras: id, reason: "project"});
            }else{
                const auxIndex = blocked.findIndex(proj => proj.id_obras == id);
                const auxDivIndex = divBlocked.projects.findIndex(p => p.id_obras == id); 
                const auxDivIndexMyProject = divBlocked.myprojects.findIndex(p => p.id_obras == id); 

                if(auxIndex > -1){
                    blocked.splice(auxIndex, 1); 
                }

                if(auxDivIndex > -1){
                    divBlocked.projects.splice(auxDivIndex, 1); 
                }
                if(auxDivIndexMyProject>-1){
                    divBlocked.myprojects.splice(auxDivIndexMyProject, 1); 
                }
            }
    
            ls.set('blockedProjects', blocked); 
            ls.set('blocked', divBlocked); 
        }
    }else{
        let blocked = ls.get('blocked'); 
        if(blocked !== null){
            if(added){
                blocked.myprojects.push({id_obras: id, reason: "myproject"})
            }else{
                const auxIndex = blocked.myprojects.findIndex(proj => proj.id_obras == id);
                if(auxIndex > -1){
                    blocked.myprojects.splice(auxIndex, 1); 
                }
            }
        }
        ls.set('blocked', blocked); 
    }
}