import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import logo_televisa from '../../../static/images/logo-televisa.svg';
import logoAcervoLiterario from "../../../static/images/logo-acervo-literario.png";
import brand from '../../utils/brand';
import Loader from '../Loader/Loader';
import CustomNotification from '../Notification/CustomNotification';
import PapperBlock from './../PapperBlock/PapperBlock';
import './style.css';
import styles from './user-jss';

//Language
//import langstr from '../../language/lang'; 
import { LanguageConsumer } from './../../language/LanguageContext';

//Services 
import {
  afterADFSLogin,
  afterInternalLogin, changePasswordRequired,
  getCurrentAuthenticated, userLogin
} from '../../services/cognito_service';

// validation functions
//const required = value => (value == null ? 'Required' : undefined);
/*const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email'
    : undefined
);*/

//AHDA 16/04/2019
//Added dependencies for Hub login 
import { Hub } from 'aws-amplify';
import OAuthButton from './OAuthButton';
import { apiPOST } from '../../services/service_base';

class LoginForm extends React.Component {
  state = {
    //Passwords 
    showPassword: false,
    showConfirm: false,
    showNew: false,
    email: '',
    password: '',
    newPwd: '',
    confirmPwd: '',
    //Loading
    isLoading: false,
    step: 'login',
    //Errors 
    open: false,
    errMsg: "errMsg",
    errPwd: false
  }

  //AHDA 30/04/2019
  //Added constructor
  _isMounted = false;
  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    Hub.listen('auth', this);
  }

  //AHDA 30/04/2019
  //Added component did mount
  //Lifecycle
  componentDidMount() {
    //TODO: Add error messages 
    getCurrentAuthenticated().then(user => {
      if (!this._isMounted) {
        this._syncUserWithDB(user, user.attributes);
        this._isMounted = true;
      }

      /*getCurrentUserInfo().then(response =>{
        console.log("Current attributes: ", response);
        
      }).catch(err => 
        console.log("current info", err));*/
    }).catch(e => {
      console.log(e);
    });
  }

  //AHDA 30/04/2019
  //Method for hub, auth with ADFS 
  onHubCapsule(capsule) {
    const { channel, payload, source } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          //TODO: add error messages
          getCurrentAuthenticated().then(user => {

            if (!this._isMounted) {
              this._syncUserWithDB(user, user.attributes);
              this._isMounted = true;
            }

            /*getCurrentUserInfo().then(response =>{
              console.log("Current attributes: ", response);
              
            }).catch(err => 
              console.log("current info", err));*/
          }).catch(e => {
            console.log(e);
          });
          break;

        case 'signIn_failure':
          console.log('not signed in');
          break;
        default:
          break;
      }
    }
  }

  //AHDA 30/04/2019
  //Method for synching an ADFS user into de DB 
  _syncUserWithDB = (user, attributtes) => {

    let decode = user.signInUserSession.idToken.payload;
    apiPOST("/catalogue/list", {}, true)
      .then((res) => {
        localStorage.setItem("catalogue_list", JSON.stringify(res.data))
      })
      .catch((err) => console.log(err))
    //AHDA 24/05/2019: Added validation and logic for external users
    if (decode["cognito:groups"]) {
      //cognito users only have one rol, ADFS users have two
      if (decode["identities"] == undefined) { //JCMY 25/07/2019 incidencia 1080

        const user_auth = {
          username: user.username
        }

        afterInternalLogin(user, user_auth).then(response => {
          window.location.href = this.props.redirect ? this.props.redirect : "/acervo";
        }).catch(err => {
          console.log(err);
          let { status } = err;

          //New password required 
          if (status === 403) {
            //window.location.href = '/changeRequired'
            this.setState({ isLoading: false, step: 'changeRequired', errPwd: false })
          }
          //Wrong username or password 
          else if (status === 401) {
            this.setState({ isLoading: false, errPwd: true, password: '' })
          }
          //Wrong username or password 
          else if (status === 404) {
            this.setState({ isLoading: false, open: true, errMsg: "errBlockedUser" });
          }
          //Other errors
          else {
            this.setState({ isLoading: false, open: true, errMsg: "errMsg" });
          }
        });
      }
      else {
        //AHDA 14/05/2019
        //Getting user from database
        const body = {
          cognitoUser: attributtes.email,
          portal: 1,
          name: attributtes.name ? attributtes.name : null,
          lastName: attributtes.family_name ? attributtes.family_name : null,
          numEmpl: attributtes["custom:employeeID"] ? attributtes["custom:employeeID"] : null,
          profile: attributtes.profile ? attributtes.profile : null,
          businessUnit: attributtes["custom:profileAD"] ? attributtes["custom:profileAD"] : null
        }

        afterADFSLogin(body).then(response => {
          window.location.href = '/acervo';
        }).catch(err => {
          let { status } = err;
          if (status === 404) {
            this.setState({ isLoading: false, open: true, errMsg: "errBlockedUser" });
          }
          //Other errors
          else {
            this.setState({ isLoading: false, open: true, errMsg: "errMsg" });
          }
        });
      }
    }

  }

  //Controls
  handleClickShowPassword = (pwd) => {
    switch (pwd) {
      case 'current':
        this.setState({ showPassword: !this.state.showPassword });
        break;
      case 'new':
        this.setState({ showNew: !this.state.showNew });
        break;
      case 'confirm':
        this.setState({ showConfirm: !this.state.showConfirm });
        break;
      default:
        break;
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  //Submit
  handleSubmit = (event) => {
    event.preventDefault();

    const user = {
      username: this.state.email,
      password: this.state.password
    }

    //AHDA 12/04/2019
    //Obtained redirect prop to redirect after login success
    const { redirect } = this.props;

    switch (this.state.step) {
      case 'login':
        this.setState({ isLoading: true });
        userLogin(user).then(
          response => {
            //AHDA 12/04/2019
            //Redirect function
            //window.location.href = redirect ? redirect : "/acervo";
          }
        ).catch(
          err => {
            console.log(err);
            let { status } = err;

            //New password required 
            if (status === 403) {
              //window.location.href = '/changeRequired'
              this.setState({ isLoading: false, step: 'changeRequired', errPwd: false })
            }
            //Wrong username or password 
            else if (status === 401) {
              this.setState({ isLoading: false, errPwd: true, password: '' })
            }
            //Wrong username or password 
            else if (status === 404) {
              this.setState({ isLoading: false, open: true, errMsg: "errBlockedUser" });
            }
            //Other errors
            else {
              this.setState({ isLoading: false, open: true, errMsg: "errMsg" });
            }

          }
        )
        break;
      case 'changeRequired':
        if (this.state.newPwd === this.state.confirmPwd) {
          this.setState({ isLoading: true, errPwd: false })
          //Set new password 
          changePasswordRequired(user, this.state.newPwd).then(
            response => {
              //AHDA 12/04/2019
              //Redirect function
              window.location.href = redirect ? redirect : "/acervo";
            }
          ).catch(
            err => {
              console.log(err);
              //Password does not match policy 
              if (err.status === 403) {
                this.setState({ isLoading: false, errPwd: false, errPolicy: true })
              }
              else {
                this.setState({ isLoading: false, open: true, errPwd: false, errPolicy: false })
              }
            }
          )
        } else {
          this.setState({ errPwd: true })
        }
        break;
      default:
        break;
    }
  }

  //Notifications
  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.formWrap}>
              <PapperBlock whiteBg
                // title={lang.login.login} desc=""
                noTitle
              >
                {
                  this.state.isLoading ? <Loader text={lang.login.logginIn} /> :
                    <form onSubmit={this.handleSubmit}>
                      {
                        {
                          'login':
                            <React.Fragment>
                              {/* <div>
                      <FormControl className={classes.formControl} error={this.state.errPwd}>
                        <InputLabel htmlFor="email">{lang.common.email}</InputLabel>
                        <Input
                          id="email"
                          name="email"
                          value={this.state.email}
                          required
                          onChange={this.handleChange}
                          inputProps={
                            {maxLength: 50}
                          }
                        />
                      </FormControl>
                    </div> */}
                              {/* <div>
                      <FormControl className={classNames(classes.formControl, classes.noMarginB)} error={this.state.errPwd}>
                        <InputLabel htmlFor="password">{lang.common.password}</InputLabel>
                        <Input
                          id="password"
                          name="password"
                          value={this.state.password}
                          type={this.state.showPassword ? 'text' : 'password'}
                          required
                          autoComplete="off"
                          endAdornment= {
                            <InputAdornment position="end">
                            <Tooltip title="Ver/Ocultar Contraseña">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={() => this.handleClickShowPassword('current')}
                              >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </Tooltip>
                            </InputAdornment>
                          }
                          onChange={this.handleChange}
                          inputProps={
                            {maxLength: 60}
                          }
                        />
                        {this.state.errPwd ? <FormHelperText id="weight-helper-text" error>{lang.login.incorrectUser}</FormHelperText> : null }
                      </FormControl>
                    </div> */}
                              {/* <div className={classes.btnArea}>
                      <FormControlLabel control={<Field name="remember" component={Checkbox} />} label={lang.login.remember} />
                      <Button variant="contained" size="small" color="primary" type="submit">
                        {lang.common.continue}
                        <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                      </Button>
                    </div> */}

                              {/* <ContentDivider content="O"/> */}
                              <div className={classes.wrapperLogin}>

                                <Hidden mdUp>
                                  <div className={classes.brandMovil}>
                                    <img src={logo_televisa} alt={brand.name} />
                                  </div>
                                </Hidden>

                                <div className={classes.logoAcervoLiterarioContainer}>
                                  <img src={logoAcervoLiterario} alt="Acervo Literario" />
                                </div>

                                <OAuthButton />
                                <Typography className={classes.help}>
                                  Si tienes problemas para ingresar comunícate al <strong>CAT</strong> extensión <strong>12345</strong>
                                </Typography>
                              </div>
                              {/* <OAuthButton />
                    <Typography className={classes.help}>
                      Si tienes problemas para ingresar al Portal Acervo Literario comunícate al <strong>CAT</strong> a la extensión <strong>12345</strong>
                    </Typography> */}
                            </React.Fragment>,
                          'changeRequired':
                            <React.Fragment>
                              <Typography variant="subtitle2" gutterBottom >
                                Por seguridad, debes cambiar la contraseña que se envió a tu correo electrónico
                              </Typography>
                              <div>
                                <FormControl className={classes.formControl} required={true} error={this.state.errPwd || this.state.errPolicy}>
                                  <InputLabel htmlFor="new-pwd">{lang.login.newPwd}</InputLabel>
                                  <Input
                                    id="new-pwd"
                                    value={this.state.newPwd}
                                    type={this.state.showNew ? 'text' : 'password'}
                                    name="newPwd"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Tooltip title="Ver/Ocultar Contraseña">
                                          <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => this.handleClickShowPassword('new')}
                                          >
                                            {this.state.showNew ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    }
                                    inputProps={
                                      { maxLength: 60 }
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div>

                                <FormControl className={classes.formControl} required={true} error={this.state.errPwd || this.state.errPolicy}>
                                  <InputLabel htmlFor="confirm-pwd">{lang.login.confirmPassword}</InputLabel>
                                  <Input
                                    id="confirm-pwd"
                                    name="confirmPwd"
                                    value={this.state.confirmPwd}
                                    type={this.state.showConfirm ? 'text' : 'password'}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Tooltip title="Ver/Ocultar Contraseña">
                                          <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => this.handleClickShowPassword('confirm')}
                                          >
                                            {this.state.showConfirm ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    }
                                    inputProps={
                                      { maxLength: 60 }
                                    }
                                  />
                                  {this.state.errPwd ? <FormHelperText id="weight-helper-text">{lang.login.passNoMatch}</FormHelperText> : null}
                                  {this.state.errPolicy ? <FormHelperText>{lang.login.pwdPolicy}</FormHelperText> : null}
                                </FormControl>
                              </div>
                              <div className={classes.btnArea}>
                                {/*<FormControlLabel control={<Field name="remember" component={Checkbox} />} label={lang.login.remember} />*/}
                                <Button variant="contained" color="primary" type="submit" size="small">
                                  {lang.common.continue}
                                  <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                                </Button>
                              </div>
                            </React.Fragment>
                        }[this.state.step]
                      }
                    </form>
                }
              </PapperBlock>

              {/*for error notifications*/}
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                <CustomNotification
                  onClose={this.handleClose}
                  variant="error"
                  message={lang.common[this.state.errMsg]} />
              </Snackbar>
            </div>
        }
      </LanguageConsumer>
    );
  }
}

//AHDA 12/04/2019
//Added redirect
LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.string
};

export default withStyles(styles)(LoginForm);
