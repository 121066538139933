import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { LanguageConsumer } from './../../language/LanguageContext';
// Import icon
import Icon from '@material-ui/core/Icon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Menu Object
import MenuContent from '../../utils/menu';
import styles from './sidebar-jss';
import ls from 'local-storage';
import { apiPOST, returnCurrentUserId } from '../../services/service_base';
import { UIContextActions, withUIContext } from '../../context';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';

class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dense: true,
      open: false,
      dynamicMenu: [],
      fatherKeys: [],
      show: false,
      link: ""
    }
  }

  componentDidMount() {
    /*MenuContent.map((item) => {
      if(item.child !== undefined){
        this.setState({[item.key]: false}); 
      }
    }); */
    let auxMenu = new Array();

    //AHDA 25/04/2019
    //Added petition to get configuration and add notifications option 
    let auxConfiguration = undefined;

    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/notifications/getConfiguration', { portal: "acervo", user_id: returnCurrentUserId() }, true).then(
      res => {
        auxConfiguration = res.data;
      }
    ).catch(err => {
      auxConfiguration = undefined;
    }).finally(() => {

      //AHDA 16/05/2019: Added valdation for refreshing
      apiPOST("/roles/getmenuaction", {
        rolId: ls.get("user_object").rdsUser.id_rol
      }, true).then(response => {
        let menuPermissions = response.data;
        ls.set("user_permissions", response.data);

        if (menuPermissions != null) {
              const list = ls.get("catalogue_list")
              MenuContent[1].child = list.map((item) => ({ key: item.id_catalogue, name: item.cat_name, link: item.cat_link }));

              MenuContent.map(item => {
                let permission = menuPermissions.filter(x => x.menu_id === item.id)[0];

                if (item.id === 0) {

                  let aux = new Array();
                  item.child.map(item => {
                    let submenu = menuPermissions.filter(x => x.menu_id === item.id)[0];
                    if (submenu != undefined) {

                      let c = JSON.parse(submenu.grant_configuration);

                      if (submenu.menu_id === 8) {
                        if (c.chkViewProfileAdmin) {
                          aux.push(item);
                        }
                      }

                      if (submenu.menu_id === 7) {
                        if (c.chkViewUser) {
                          aux.push(item);
                        }
                      }

                      if (submenu.menu_id === 18) {
                        if (c.chkViewParameter) {
                          aux.push(item);
                        }
                      }

                      /*TODO ADD CONG NOTIG*/
                      if (submenu.menu_id === 10) {
                        if (c.chkViewConfig || c.chkViewNoti) {
                          aux.push(item);
                        }

                      }

                      if (submenu.menu_id === 3) {
                        if (c.chkViewWorkAdmin) {
                          aux.push(item);
                        }
                      }
                    }

                  });

                  if (aux.length > 0) {
                    let i = item;
                    i.child = aux;
                    auxMenu.push(i);
                  }

                }

                if (permission != undefined) {
                  let config = JSON.parse(permission.grant_configuration);
                  if (item.id === 2) {
                    let auxChild = new Array();

                    if (config.chkShowMovementHistory) {
                      auxChild.push(item.child[0]);
                    }

                    if (config.chkCompAcervoLiterario) {
                      auxChild.push(item.child[1]);
                    }

                    if (config.chkWorkMoreView) {
                      auxChild.push(item.child[3]);
                    }

                    if (config.chkViewFavorite) {
                      auxChild.push(item.child[2]);
                    }

                    if (config.chkViewProspect) {
                      auxChild.push(item.child[4]);
                    }

                    if (config.chkViewProject) {
                      auxChild.push(item.child[5]);
                    }
                    if (config.chkViewControlMat) {
                      auxChild.push(item.child[6]);
                    }

                    if (config.chkViewUsers) {
                      // Bug, no se está trayendo el item.child[6] del menu de usuarios
                      if (item.child[7] != undefined){
                        auxChild.push(item.child[7]);
                      } else {
                        console.log("item.child[7] es undefined");
                      }
                    }

                    if (auxChild.length > 0) {
                      item.child = auxChild;
                      auxMenu.push(item);
                    }
                  } else if (item.id === 6) {
                    if (config.chkViewCatalog) {
                      auxMenu.push(item);
                    }
                  } else if (item.id === 5) {
                    if (config.chkViewProfile) {
                      auxMenu.push(item);
                    }
                  }
                  //AHDA 25/04/2019
                  //Added validation for showing Acervo
                  else if (item.id === 1) {
                    if (config.chkProductionGeneralView || config.chkSalesGeneralView) {
                      auxMenu.push(item);
                    }
                  }
                  else {
                    //AHDA 25/04/2019
                    //Added validation for notifications
                    //Temporal validation, delete when merge
                    if (item.id !== 9) {
                      auxMenu.push(item);
                    }
                  }

                } else if (permission == undefined && item.id === 9) {

                  //AHDA 25/04/2019
                  //Getting notification configuration 
                  //Get notification configuration
                  if (auxConfiguration) {
                    auxConfiguration = auxConfiguration.filter(f => f.send_web_usr === 1);
                    if (auxConfiguration.length > 0) {
                      //Get option menu for notifications 
                      auxMenu.push(MenuContent.find(m => m.id === 9));
                    }
                  }
                }
              });
              this.setState({
                dynamicMenu: auxMenu
              });
        }

      }).catch(err => console.log("error menu action", err));

    })
  }

  handleClick = (link) => {
    if (this.props.uicontext.state.isCatalogEdit) {
      this.setState({ show: true, link })
    } else {
      this.props.toggleDrawerOpen();
      this.props.loadTransition(false);
    }

  }

  handleClickExpand = key => {
    this.setState({ [key]: !this.state[`${key}`] });
  }

  handleConfirmSwal = (confirm) => {
    this.setState({ show: false })
    if (confirm) {
      UIContextActions.catalogEdit(this.props.uicontext);
      this.props.history.push(this.state.link);
    }

  }

  render() {
    const {
      classes,
      uicontext
    } = this.props;

    //AHDA 04/04/2019
    //Bug correction
    const renderSubmenu = child => child.map((item, index) => (
      <React.Fragment key={`submenu_${index.toString()}`}>
        <ListItem
          button
          exact
          className={classNames(classes.nested, classes.pUnset)}
          activeClassName={classes.active}
          component={uicontext.state.isCatalogEdit ? "div" : NavLink}
          to={uicontext.state.isCatalogEdit ? "false" : `${item.link}`}
          onClick={() => this.handleClick(item.link)}
          dense={true}
        >
          {item.icon &&
            <ListItemIcon>
              <Icon className={classes.icon}>{item.icon}</Icon>
            </ListItemIcon>
          }
          <ListItemText className={classNames(classes.primary, classes.paddingtext)} inset primary={item.name} />
        </ListItem>
      </React.Fragment>
    ))

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div>
              {this.state.dynamicMenu.map((item, index) =>
              (
                <React.Fragment key={`submenu_${index.toString()}`}>
                  {
                    item.child === undefined ?
                      <ListItem
                        button
                        exact={true}
                        // CH 10/05/2019
                        // Se cambian estilos
                        className={classNames(classes.nested, "itemText")}
                        activeClassName={classes.active}
                        component={uicontext.state.isCatalogEdit ? "div" : NavLink}
                        to={uicontext.state.isCatalogEdit ? "false" : `${item.link}`}
                        onClick={() => this.handleClick(item.link)}
                      >
                        {item.icon &&
                          <ListItemIcon>
                            <Icon className={classes.icon}>{item.icon}</Icon>
                          </ListItemIcon>
                        }
                        <ListItemText classes={{ primary: classes.primary }} inset primary={lang.menu[`${item.key}`]} />
                      </ListItem> :
                      <ListItem
                        button
                        className={classNames(classes.nested, "itemText")}
                        onClick={() => this.handleClickExpand(item.key)}
                      >
                        {item.icon &&
                          <ListItemIcon>
                            <Icon className={classes.icon}>{item.icon}</Icon>
                          </ListItemIcon>
                        }
                        <ListItemText classes={{ primary: classes.primary }} inset primary={lang.menu[`${item.key}`]} />
                        {this.state[`${item.key}`] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                  }
                  {
                    item.child !== undefined ?
                      <Collapse in={this.state[`${item.key}`]} timeout="auto" unmountOnExit>
                        {renderSubmenu(item.child)}
                      </Collapse> : ''
                  }
                </React.Fragment>
              )
              )}
              <SweetAlert
                show={this.state.show}
                customClass={classes.alertDialog}
                title=""
                text="¿Estás seguro de descartar los cambios realizados?"
                onConfirm={() => this.handleConfirmSwal(true)}
                onCancel={() => this.handleConfirmSwal(false)}
                showCancelButton
                confirmButtonText='Sí, descartar'
                cancelButtonText="Cancelar"
              />
            </div>
        }
      </LanguageConsumer>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  drawerPaper: PropTypes.bool
};

export default withUIContext(withRouter(withStyles(styles)(MainMenu)));