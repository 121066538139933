import React from 'react';
import classNames from 'classnames';
import { ReactiveBase, DataSearch, SelectedFilters, ReactiveList, SingleDataList, SingleList } from '@appbaseio/reactivesearch';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FilterList from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import ls from 'local-storage';

import brand from '../../../utils/brand';
import ProductDetail from '../../../components/Gallery/ProductDetail';
import CustomNotification from '../../../components/Notification/CustomNotification';
import ProductGallery from '../../../components/Gallery/ProductGallery';
import { LanguageConsumer } from '../../../language/LanguageContext';
import { Button } from '../../../atoms';
import SearchDrawer from '../../../components/Search/SearchDrawer';

//Styles
import styles from '../../../components/Search/search-jss';
import './searchs-css.css';

//Services
import { apiPOST, returnCurrentUserId } from '../../../services/service_base';
import { gaEvents } from '../../../services/gaEvents';
import { UIContextActions, withUIContext } from '../../../context';
import { filterByAcervoActives } from '../../../utils/validation';
import { Link } from 'react-router-dom';

const category_order = {
    "Telenovelas": 1,
    "Unitarios": 2,
    "Series": 3,
    "Bioserie": 4,
    "Otros": 5,
}

class GlobalSearch extends React.Component {

    //AHDA 30/04/2019
    //Added permissions
    state = {
        //Drawer
        isOpen: false,
        //Open detail 
        open: false,
        selected: 0,
        selectedProduct: [],
        detailWork: 0,
        loading: false,
        //Notifications
        openError: false,
        messageError: 'errMsg',
        variantError: 'error',
        //Filters
        genre: [],
        audience: [],
        country: [],
        //Initial filter: 
        initialSearch: '',
        //Permissions 
        chkFavorite: false,
        chkProductionGeneralView: false,
        chkSalesGeneralView: false,
        chkApplyForMaterial: false,
        chkShareMaterial: false,
        chkViewWorkBlock: false,
        chkViewScripts: false,
        clasification: "none",
        dataS: false,
        stream: false,
        //Force Refreshing
        projects: 0, //AHDA 19/06/2019
        queryshould: [],
        loaded: false,
        productor: false
    };



    //OPEN DETAIL
    handleDetailOpen = (product) => {
        this.setState(
            {
                detailWork: 0
            }, () => {
                const request = {
                    user: returnCurrentUserId(),
                    idObra: product.id_obras,
                    screen: 'search'
                }
                //MARK AS SEARCH IN AUDIT
                apiPOST('/obras/markAsSearch', request);

                if (this.state.open) {
                    this.setState({ selected: product.id_obras, selectedProduct: product, loading: true });
                }
                else {
                    this.setState({ open: true, selected: product.id_obras, selectedProduct: product, loading: true });
                }

                //AHDA 24/04/2019
                //Added clasification param 
                // Obteniendo detalle de la obra
                const requestGetObra = {
                    idObra: product.id_obras,
                    clasification: this.state.clasification
                }

                apiPOST('/obras/getDetail', requestGetObra).then(response => {
                    if (response.status === 200) {
                        this.setState({ detailWork: response.data, loading: false })
                    } else {
                        this.setState({ openError: true, loading: false, open: false });
                    }
                }).catch(error => {
                    this.setState({ openError: true, loading: false, open: false });
                });
            }
        )
    };

    //CLOSE DETAIL
    handleClose = () => {
        this.setState({ open: false, selected: 0, selectedProduct: [] });
    };

    //FOR PROSPECTS
    handleNotifications = () => {
        this.setState({ openError: true, messageError: 'errProspects', variantError: 'info' });
    }

    handleCloseNotification = () => {
        this.setState({ openError: false });
    }

    //AHDA 19/06/2019: Method for refreshing projects 
    handleAddTo = (work, type, added, external) => {
        let auxProjects = this.state.projects;
        if (type === 101) {
            auxProjects = added ? auxProjects + 1 : auxProjects - 1;
            this.setState({ projects: auxProjects });
        }
    }


    handleQueryChange = async (prev, next) => {
        // avoid fetching the results for match_all query since dataset is large
    };

    componentDidMount() {
        UIContextActions.hideDrawer(this.props.uicontext)
        let chkDetail;

        const url = new URL(window.location.href), search = url.searchParams.get("search"), filter = url.searchParams.get("filter");

        let clasification = "none";
        if (ls.get("user_permissions") != null) {

            let config = ls.get("user_permissions").filter(x => x.menu_id === 1)[0];
            let chk = config ? JSON.parse(config.grant_configuration) : undefined;

            let configDetail = ls.get("user_permissions").filter(x => x.menu_id === 4)[0];
            chkDetail = configDetail ? JSON.parse(configDetail.grant_configuration) : undefined;

            if (chk.chkProductionGeneralView && chk.chkSalesGeneralView) {
                clasification = "none";
            }
            else if (!chk.chkProductionGeneralView && chk.chkSalesGeneralView) {
                clasification = "none";
            }
            else if (chk.chkProductionGeneralView && !chk.chkSalesGeneralView) {
                clasification = "produccion";
                this.setState({ productor: true })
            }

            if (ls.get("user_object").rdsUser.asigned_profile === "Productor" || ls.get('productionView')) {
                this.setState({ productor: true });
                clasification = "produccion";
            }


            this.setState({
                chkFavorite: chk ? chk.chkFavorite : false,
                chkProductionGeneralView: chk ? chk.chkProductionGeneralView : false,
                chkSalesGeneralView: chk ? chk.chkSalesGeneralView : false,
                chkApplyForMaterial: chkDetail ? chkDetail.chkApplyForMaterial : false,
                chkShareMaterial: chkDetail ? chkDetail.chkShareMaterial : false,
                chkViewWorkBlock: chkDetail ? chkDetail.chkViewWorkBlock : false,
                chkViewScripts: !chkDetail ? false : chkDetail.chkViewWorkBlock ? chkDetail.chkViewWorkBlock : false,
                chkViewSinopsis: !chkDetail ? false : chkDetail.chkViewSinopsis ? chkDetail.chkViewSinopsis : false,
                chkViewPersonaje: !chkDetail ? false : chkDetail.chkViewPersonaje ? chkDetail.chkViewPersonaje : false,
                chkViewEval: !chkDetail ? false : chkDetail.chkViewEval ? chkDetail.chkViewEval : false,
                chkViewMaterial: !chkDetail ? false : chkDetail.chkViewMaterial ? chkDetail.chkViewMaterial : false,
                chkViewMoreInfo: !chkDetail ? false : chkDetail.chkViewMoreInfo ? chkDetail.chkViewMoreInfo : false,
                chkViewVigencia: !chkDetail ? false : chkDetail.chkViewVigencia ? chkDetail.chkViewVigencia : false,
                chkViewEquiposLit: !chkDetail ? false : chkDetail.chkViewEquiposLit ? chkDetail.chkViewEquiposLit : false,
                chkDownloadFT: !chkDetail ? false : chkDetail.chkDownloadFT ? chkDetail.chkDownloadFT : false,
                clasification: clasification
            });

        }

        const queryshould = chkDetail && chkDetail.chkViewWorkBlock ? [] : [
            {
                "match": {
                    "valid": 1
                }
            },
            {
                "bool": {
                    "must_not": {
                        "exists": {
                            "field": "valid"
                        }
                    }
                }
            }
        ];

        if (filter) {
            const keyword = filter.split("_")[0]
            const value = filter.split("_")[1]
            this.setState({ filter: { keyword, value } })
            //queryshould.push({"bool":{"should":[{"terms":{ [keyword]: value.split(",") }}]}})
        }
        this.setState({ initialSearch: search !== null ? search : '', queryshould, loaded: true });
    }

    handleFilters = (e) => {
        if (e && e !== "") {
            ls.set('productionView', e === "CATÁLOGO DE PRODUCCIÓN" ? true : false)
            this.setState({ productor: e === "CATÁLOGO DE PRODUCCIÓN" ? true : false })
        }


    }

    renderCards = (item) => {
        if (item.length > 0) {
            let category = [];
            item.map(
                i => {
                    if (!category.find(x => x.id_categoria === i.id_categoria)) {
                        let newData = [];
                        let aux = item.filter(x => x.id_categoria === i.id_categoria);
                        aux.map(
                            (item) => {
                                newData.push(item);
                            }
                        );
                        category.push(
                            {
                                cat_order: category_order[i.id_categoria],
                                categoria: i.categoria,
                                id_categoria: i.id_categoria,
                                obras: newData,
                                total: newData.length
                            }
                        );
                    }
                }
            );

            return category.sort((a, b) => a.cat_order - b.cat_order).map((item, index) => {
                if (item.total > 0)
                    return <div className="container" key={index.toString()}>
                        <Link style={{textDecoration: 'none'}} to={`/acervo/search-category?category=${item.id_categoria}${this.state.productor ? '&view=productor' : ''}`}>
                            <Typography variant="headline" component="h2" className={this.props.classes.titleCategory}>
                                {item.categoria ? item.categoria : "Sin categoría"} <small>({item.total})</small>
                            </Typography>
                        </Link>
                        <ProductGallery
                            dataProduct={item.obras}
                            handleNotifications={() => this.handleNotifications()}
                            screen={'home'}
                            canFavorite={this.state.chkFavorite}
                            canApplyForMaterial={this.state.chkApplyForMaterial}
                            canShareMaterial={this.state.chkShareMaterial}
                            canViewWorkBlock={this.state.chkViewWorkBlock}
                            addToList={this.handleAddTo}
                            chkProductionGeneralView={this.state.chkProductionGeneralView}
                            chkSalesGeneralView={this.state.chkSalesGeneralView}
                            chkViewScripts={this.state.chkViewScripts}
                            chkViewSinopsis={this.state.chkViewSinopsis}
                            chkViewPersonaje={this.state.chkViewPersonaje}
                            chkViewEval={this.state.chkViewEval}
                            chkViewMaterial={this.state.chkViewMaterial}
                            chkViewMoreInfo={this.state.chkViewMoreInfo}
                            chkViewVigencia={this.state.chkViewVigencia}
                            chkViewEquiposLit={this.state.chkViewEquiposLit}
                            chkDownloadFT={this.state.chkDownloadFT}
                            favorites={this.state.favorites}
                            prospects={this.state.prospects}
                            projects={this.state.projects}
                            productor={this.state.productor}
                        />
                    </div>
                else
                    return null;
            })
        } else {
            return null;
        }
    }

    render() {
        //Title 
        const title = brand.name + ' - Resultados de Búsqueda';
        const description = brand.desc;

        //Styles
        const { classes } = this.props;
        //Detail
        const { open } = this.state;
        //AHDA 25/04/2019 
        //Added method to return permissions validations 
        const getDefaultFilter = (type) => {
            if (ls.get("user_permissions") != null) {

                let config = ls.get("user_permissions").filter(x => x.menu_id === 1)[0];
                let chk = config ? JSON.parse(config.grant_configuration) : undefined;

                switch (type) {
                    case "class":
                        if (chk.chkProductionGeneralView && chk.chkSalesGeneralView && ls.get("user_object").rdsUser.asigned_profile !== "Productor") {
                            return "";
                        } else {
                            return "clasification-hidden";
                        }
                    case "bool-all":
                        if ((chk.chkProductionGeneralView && chk.chkSalesGeneralView) && !this.state.productor) {
                            return true;
                        }
                        else if (!chk.chkProductionGeneralView && chk.chkSalesGeneralView) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    case "bool-prod":
                        if (chk.chkProductionGeneralView && !chk.chkSalesGeneralView) {
                            return true;
                        }
                        if (this.state.productor) {
                            return true;
                        } else {
                            return false;
                        }
                    case "array":
                        if ((chk.chkProductionGeneralView && chk.chkSalesGeneralView) && !this.state.productor) {
                            return "CATÁLOGO COMPLETO";
                        }
                        else if (!chk.chkProductionGeneralView && chk.chkSalesGeneralView) {
                            return "CATÁLOGO COMPLETO";
                        }
                        else if (chk.chkProductionGeneralView && !chk.chkSalesGeneralView) {
                            return "CATÁLOGO DE PRODUCCIÓN";
                        }
                        if (this.state.productor) {
                            return "CATÁLOGO DE PRODUCCIÓN";
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return (
            <LanguageConsumer>
                {
                    ({ lang }) =>
                        <div className={classes.root}>
                            <Helmet>
                                <title>{title}</title>
                                <meta name="description" content={description} />
                                <meta property="og:title" content={title} />
                                <meta property="og:description" content={description} />
                                <meta property="twitter:title" content={title} />
                                <meta property="twitter:description" content={description} />
                            </Helmet>
                            {
                                this.state.loaded ?
                                    <ReactiveBase
                                        url={process.env.REACT_APP_ELASTICBEHAVIOR}
                                        app={process.env.REACT_APP_ELASTICINDEX}
                                    >
                                        <Grid
                                            item
                                            container
                                            md={12}
                                            alignItems="center"
                                            justify="flex-end"
                                            direction="row"
                                            className={classNames(
                                                "filters-clasification",
                                                classes.contentWidth,
                                                { [classes.contentWidthShift]: this.state.isOpen }
                                            )}>
                                            {
                                                <SingleDataList
                                                    componentId="ClasificationSensor"
                                                    dataField="obra_vigencia.keyword"
                                                    showSearch={false}
                                                    data={
                                                        [{
                                                            label: "CATÁLOGO DE PRODUCCIÓN",
                                                            value: "Vigente"
                                                        }, {
                                                            label: "CATÁLOGO COMPLETO",
                                                            value: ""
                                                        }]
                                                    }
                                                    selectAllLabel='CATÁLOGO COMPLETO'
                                                    className={getDefaultFilter("class")}
                                                    showCount={false}
                                                    innerClass={{
                                                        label: 'check-label',
                                                        radio: 'check-search-radio'
                                                    }}
                                                    filterLabel="Clasificación"
                                                    defaultValue={getDefaultFilter("array")}
                                                    showFilter={false}
                                                    onValueChange={this.handleFilters}
                                                    enableStrictSelection={true}
                                                />
                                            }
                                        </Grid>
                                        <div className={classes.searchWrapper}>
                                            <div className={classes.searchBox}>
                                                <div className={classes.searchIcon}>
                                                    <SearchIcon />
                                                </div>
                                                <DataSearch
                                                    showIcon={false}
                                                    filterLabel="Buscar"
                                                    className={classes.searchInput}
                                                    dataField={["titulo_original", "titulo_ingles", "titulo_otro_idioma", "genero", "subgenero", "horario_sugerido", "audiencia", "pais", "autores", "formato", "tipo_obra", "produccion", "logline", "categoria", "fuente_data", "ventana", "tags", "obra_vigencia"]}
                                                    componentId="SearchSensor"
                                                    innerClass={{
                                                        input: 'search-input'
                                                    }}
                                                    placeholder="Buscar"
                                                    onValueSelected={gaEvents.eventSearch(this.state.initialSearch)}
                                                    onValueChange={(e) => this.setState({ initialSearch: e })}
                                                    defaultValue={this.state.initialSearch}
                                                    defaultQuery={() => filterByAcervoActives}
                                                />
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => UIContextActions.toggleDrawer(this.props.uicontext)}
                                                        className={classes.filtroBtn}>
                                                        Filtros <FilterList />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <Grid
                                            className={classNames(
                                                classes.contentWidth,
                                                { [classes.contentWidthShift]: this.state.isOpen }
                                            )}
                                            container
                                            justify="flex-end"
                                            direction="row">

                                            <Grid item xs={12} md={12} className="m5">
                                                <SelectedFilters
                                                    clearAllLabel="Limpiar"
                                                    showClearAll={true}
                                                    onChange={(dataFilters) => {
                                                        console.log(dataFilters)
                                                        let keys = Object.keys(dataFilters)
                                                        let activeFilters = [];
                                                        keys.forEach(key => {
                                                            switch (dataFilters[key].componentType) {
                                                                case 'DATASEARCH':
                                                                    if (dataFilters[key].value !== '') {
                                                                        activeFilters.push(key);
                                                                    }
                                                                    break;
                                                                case 'MULTILIST':
                                                                    if (dataFilters[key].value.length > 0) {
                                                                        activeFilters.push(key);
                                                                    }
                                                                    break;
                                                            }
                                                        });

                                                        if (activeFilters.length === 0) {
                                                            window.location.href = "/acervo"
                                                        }

                                                    }}
                                                    onClear={filter => {
                                                        if (filter == null) {
                                                            window.location.href = "/acervo"
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <ReactiveList
                                            className={classNames(
                                                "right-col",
                                                classes.contentWidth,
                                                { [classes.contentWidthShift]: this.state.isOpen }
                                            )}
                                            componentId="SearchResult"
                                            dataField={["titulo_original", "titulo_ingles", "titulo_otro_idioma"]}
                                            size={20}
                                            onData={(data) => this.setState({ stream: true, dataS: data })}
                                            pagination
                                            renderResultStats={(stats) => {
                                                return this.state.initialSearch != "" ?
                                                    `${stats.numberOfResults} obras encontradas en ${stats.time} ms`
                                                    : "";
                                            }
                                            }
                                            renderNoResults={() => { return (<div><label>Lo sentimos, no se encontraron registros coincidentes</label></div>) }}
                                            paginationAt="bottom"
                                            innerClass={{
                                                pagination: 'page',
                                                active: 'activeBtn'
                                            }}
                                            react={{
                                                and: ['SearchSensor', 'GenreSensor', 'SubgenreSensor', 'HorarioSugSensor', 'AudienceSensor', 'CountrySensor', 'AuthorSugSensor', 'FormatSensor', 'TypeSensor', 'ProductionSensor'],
                                                or: ['ClasificationSensor']
                                            }}
                                            render={({ data }) => {
                                                if (!this.state.stream) {
                                                    return this.renderCards(data);
                                                } else {
                                                    return (null)
                                                }
                                            }
                                            }
                                            onQueryChange={this.handleQueryChange}
                                            defaultQuery={() => filterByAcervoActives}
                                        />

                                        <SearchDrawer filter={this.state.filter} />

                                    </ReactiveBase>
                                    : null
                            }


                            {/*For notifications*/}
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={this.state.openError}
                                autoHideDuration={6000}
                                onClose={this.handleCloseNotification}>
                                <CustomNotification
                                    onClose={this.handleCloseNotification}
                                    variant={this.state.variantError}
                                    message={lang.common[`${this.state.messageError}`]}
                                />
                            </Snackbar>

                            {
                                this.state.detailWork !== 0 &&
                                <ProductDetail
                                    open={open}
                                    close={this.handleClose}
                                    detailContent={this.state.selectedProduct}
                                    productIndex={this.state.selected}
                                    detailWork={this.state.detailWork}
                                    handleDetailOpen={this.handleDetailOpen}
                                    loading={this.state.loading}
                                    canFavorite={this.state.chkFavorite}
                                    canApplyForMaterial={this.state.chkApplyForMaterial}
                                    canShareMaterial={this.state.chkShareMaterial}
                                    canViewWorkBlock={this.state.chkViewWorkBlock}
                                    chkViewScripts={this.state.chkViewScripts}
                                    chkViewSinopsis={this.state.chkViewSinopsis}
                                    chkViewPersonaje={this.state.chkViewPersonaje}
                                    chkViewEval={this.state.chkViewEval}
                                    chkViewMaterial={this.state.chkViewMaterial}
                                    chkViewMoreInfo={this.state.chkViewMoreInfo}
                                    chkViewVigencia={this.state.chkViewVigencia}
                                    chkViewEquiposLit={this.state.chkViewEquiposLit}
                                    chkDownloadFT={this.state.chkDownloadFT}
                                    chkProductionGeneralView={this.state.chkProductionGeneralView}
                                    chkSalesGeneralView={this.state.chkSalesGeneralView}
                                    screen={'search'}
                                    addToList={this.handleAddTo}
                                    productor={this.state.productor}
                                />
                            }
                        </div>
                }
            </LanguageConsumer>
        )
    }

    componentDidUpdate() {
        if (this.state.stream) {
            this.setState({ stream: false })
        }
    }

}

GlobalSearch.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withUIContext(withStyles(styles)(GlobalSearch));